import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AsideFeed from "./AsideFeed";
import { Link, useLocation } from "react-router-dom";
import constants from "../../constants";
import ArticleNav from "../articleNav";
import { World } from "../../components/icons";
import { timeDifferenceForDate } from "../utils";

import Main from "../layout/main";
import Loader from "components/Loader";
import axios from "axios";
import { url } from "api";
import { extractImageFromZip } from "learning/utils/imageHandler";

const smartNotes = [
  "• a must read",
  "• 1 minute read",
  "• informative read",
  "• easy read",
  "• unexpected read",
];

const Feed = ({ userInfoRedux }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const paramCateogry = searchParams.get('category');
  
  const [allArticles, setAllArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(paramCateogry);

  useEffect(() => {
    loadArticles();
  }, []);

  function loadArticles() {
    axios
      .get(`${url}/api/articles`)
      .then(async (res) => {
        let articles = res.data;
        const updatedArticles = await Promise.all(
          articles.map(async (article) => {
            const authorImage = await extractImageFromZip(
              article.author.profilePhoto
            );
            article.author.profilePhoto = authorImage;
            return article;
          })
        );
        console.log(updatedArticles);
        setAllArticles(updatedArticles);
        filterArticles(updatedArticles, paramCateogry ? paramCateogry : "All");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function filterArticles(articles, category) {
    let filteredArticles =
      category == "All"
        ? articles
        : articles.filter((article) => article.category == category).reverse();
    setFilteredArticles(filteredArticles);
    setSelectedCategory(category);
  }

  const FilterArticles = (e) => {
    filterArticles(allArticles, e.target.innerText);

    // append the query param without reloading the page
    const category = e.target.innerText;
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?category='+category;
    window.history.pushState({path:newurl},'',newurl);
  };

  function clap(article_id) {
    console.log("clapping...");
    axios
      .post(`${url}/api/article/clap`, { article_id })
      .then((res) => {
        loadArticles();
      })
      .catch((err) => console.log(err));
  }

  const categoryList = ["All"];
  allArticles.forEach((article) => {
    if (article.category && !categoryList.includes(article.category)) {
      categoryList.push(article.category);
    }
  });

  const navList = categoryList.sort().map((category, index) => (
    <button
      key={index}
      className={selectedCategory === category ? "ActiveTab" : ""}
      onClick={(e) => {
        FilterArticles(e);
      }}
    >
      {category === "All" && <World />}
      <span>{category}</span>
    </button>
  ));

  const articles = filteredArticles
    .reverse()
    .sort((a, b) => {
      if (a.category === "Tutorials" && b.category !== "Tutorials") {
        return -1;
      }
      if (a.category !== "Tutorials" && b.category === "Tutorials") {
        return 1;
      }
      return 0;
    })
    .map((article) => (
      <div className="post-panel" key={article._id}>
        <div className="post-metadata">
          <img
            alt={article.author?.username}
            className="avatar-image"
            src={article.author.profilePhoto}
            height="40"
            width="40"
          />

          <div className="post-info">
            <div className="PopoverLink">
              <span className="popover-link" data-reactroot="">
                <Link to={`/learning/articles/profile/${article.author?._id}`}>
                  {article.author?.name}
                </Link>
              </span>
            </div>
            <small>
              {" "}
              Article{" "}
              {smartNotes[Math.floor(Math.random() * smartNotes.length)]}{" "}
            </small>{" "}
            <small
              style={{
                position: "absolute",
                paddingRight: "10px",
                right: "5px",
                textAlign: "right",
                align: "right",
              }}
            >
              {" "}
              Published • {timeDifferenceForDate(article.createdAt)}
            </small>
          </div>
        </div>

        {article.feature_img?.length > 0 ? (
          <div className="post-picture-wrapper">
            <Link to={`/learning/articles/${article.slug}`}>
              {" "}
              <img
                src={`${constants.SERVER_URL}/marketing/branding/hotlink-ok/${article.feature_img}`}
                alt={article.slug}
              />
            </Link>
          </div>
        ) : (
          ""
        )}

        <div className="main-body">
          <Link to={`/learning/articles/${article.slug}`}>
            <h1 className="post-title-1">{article.title}</h1>
          </Link>

          <div className="post-body">
            <h2>
              <main
                className="article-font"
                dangerouslySetInnerHTML={{ __html: article.description }}
              />
            </h2>
          </div>
          <Link to={`/learning/articles/${article.slug}`}>Read more</Link>
        </div>

        <div className="post-stats clearfix">
          <div className="pull-left">
            <div className="like-button-wrapper">
              <div className="like-button-wrapper">
                <button
                  onClick={() => clap(article._id)}
                  className="like-button"
                  data-behavior="trigger-overlay"
                  type="submit"
                >
                  <span className="text d-flex align-items-center">
                    <i className="fa fa-thumbs-up mr-2"></i> Like
                    <span className="like-count ml-3 mr-0">
                      {article.claps}
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="pull-right">
            <div className="bookmark-button-wrapper">
              <form className="button_to" method="get" action="">
                <button
                  className="bookmark-button"
                  data-behavior="trigger-overlay"
                  type="submit"
                >
                  {" "}
                  <span className="icon-bookmark-o"></span>
                  <span className="hide-text">Bookmark</span>
                </button>
              </form>
            </div>
          </div>

          <div className="response-count pull-right"></div>
        </div>
      </div>
    ));

  return (
    <>
      {allArticles.length === 0 && <Loader />}
      <div className="personal-area min-h-full">
        <div className="container-fluid learning-container-flex pt-3">
          {allArticles && (
            <ArticleNav>
              <div className="learning-sidebar">
                <p className="font-weight-bold">Articles</p>
                {navList}
              </div>
            </ArticleNav>
          )}
          <div className="learning-container-flex w-100">
            <div className="learning-content p-0">
              <Main>
                <div className="container-fluid main-container">
                  <div
                    className="col-md-offset-1 dashboard-main-content"
                    style={{ margin: "10px" }}
                  >
                    <div
                      className="posts-wrapper animated fadeInUp"
                      data-behavior="endless-scroll"
                      data-animation="fadeInUp-fadeOutDown"
                    >
                      {articles}
                    </div>
                  </div>
                </div>
              </Main>
            </div>
            {filteredArticles && (
              <AsideFeed
                selectedCategory={selectedCategory}
                _articles={filteredArticles}
                userInfoRedux={userInfoRedux}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Feed;
