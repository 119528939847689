import React, { useEffect, useState } from "react";
import { publicFetch } from "../../util/fetcher";

import Layout from "../../components/layout";
import PageTitle from "../../components/page-title";
import DetailPageContainer from "../../components/detail-page-container";
import PostWrapper from "../../components/post/post-wrapper";
import PostVote from "../../components/post/post-vote";
import PostSummary from "../../components/post/post-summary";
import CommentList from "../../components/post/comment-list";
import CommentItem from "../../components/post/comment-list/comment-item";
import AnswerContainer from "../../components/answer-container";
import AddAnswer from "../../components/add-answer";
import { Spinner } from "../../components/icons";
import Seo from "../../components/seo/Seo";

import { eventTrack } from "../../components/utils";
import GA4 from "../../components/GA4";
import { HeadProvider } from "react-head";
import Sidebar from "learning/components/layout/sidebar";
import { url } from "api";
//import { sl } from 'date-fns/locale'

//  function getslug(title) {
//   return title.toLowerCase()
//               .replace(/\s(-)\s/g, '-')
//              .replace(/ /g, '-')
//              .replace(/[^\w-]+/g, '');
// }

function unslug(slugs) {
  slugs = slugs.replace(/_/g, "-");
  slugs = slugs.replace(/--/g, "-");

  var list = [];
  var first = true;
  slugs.split("-").forEach(function (slug) {
    first
      ? list.push(slug.substr(0, 1).toUpperCase() + slug.substr(1))
      : list.push(slug);
    first = false;
  });

  return list.join(" ");
}

const QuestionDetail = ({ slug, anonymous, userInfoRedux }) => {
  console.log(slug);
  const [question, setQuestion] = useState(null);
  const [answerSortType, setAnswersSortType] = useState("Votes");
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);

  //const slug = getslug(title)
  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const { data } = await publicFetch.get(`/question/${slug}`);
        setQuestion(data);
        setLoading(false);
      } catch (error) {
        console.log({ error: error.response.data.message });
        setErrorMsg(error.response.data.message);
        setLoading(false);
      }
    };

    fetchQuestion();
    console.log();
  }, []);

  const handleSorting = () => {
    switch (answerSortType) {
      case "Votes":
        return (a, b) => b.score - a.score;
      case "Newest":
        return (a, b) => new Date(b.created) - new Date(a.created);
      case "Oldest":
        return (a, b) => new Date(a.created) - new Date(b.created);
      default:
        break;
    }
  };

  return (
    <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}>
      <HeadProvider>
        {/* <link rel="canonical" href={isClient && window.location.href}></link> */}
      </HeadProvider>
      <div className="personal-area min-h-full">
        <div className="container-fluid learning-container-flex pt-3"> 
          <Sidebar />

          <div className="learning-content">
            <PageTitle title={unslug(slug)} button />

            <DetailPageContainer>
              {loading && (
                <div className="loading">
                  <Spinner />
                </div>
              )}

              {errorMsg && <p className="p-4">{errorMsg}</p>}

              {question && (
                <>
                  <Seo
                    title={`${unslug(slug)} - BidLight Learning`}
                    description={question.text}
                    siteUrl={`${url}/learning/questions/${slug}`}
                    keywords={question.tags}
                    authorUsername={question.author}
                  />

                  {eventTrack(
                    "question",
                    question.tags,
                    question.tags,
                    3,
                    false
                  )}
                  <GA4
                    userId={slug}
                    category={"question"}
                    action={"question"}
                    label={"question"}
                    value={3}
                    nonInteraction={true}
                    transport={"xhr"}
                  ></GA4>
                  <PostWrapper borderBottom={false}>
                    <PostVote
                      score={question.score}
                      votes={question.votes}
                      questionId={slug}
                      setQuestion={setQuestion}
                    />
                    <PostSummary
                      tags={question.tags}
                      author={question.author}
                      created={question.createdAt}
                      questionId={slug}
                    >
                      {question.text}
                    </PostSummary>
                    <CommentList questionId={slug} setQuestion={setQuestion} userInfoRedux={userInfoRedux}>
                      {question.comments.map(
                        ({ slug, author, created, body }) => (
                          <CommentItem
                            key={slug}
                            commentId={slug}
                            questionId={slug}
                            author={author.username}
                            isOwner={
                              author.username === question.author.username
                            }
                            created={created}
                            setQuestion={setQuestion}
                          >
                            {body}
                          </CommentItem>
                        )
                      )}
                    </CommentList>
                  </PostWrapper>

                  {question.answers.length > 0 && (
                    <AnswerContainer
                      answersCount={question.answers.length}
                      answerSortType={answerSortType}
                      setAnswerSortType={setAnswersSortType}
                    >
                      {question.answers.sort(handleSorting()).map((answer) => (
                        <PostWrapper key={answer.id}>
                          <PostVote
                            score={answer.score}
                            votes={answer.votes}
                            answerId={answer.id}
                            questionId={slug}
                            setQuestion={setQuestion}
                          />
                          <PostSummary
                            author={answer.author}
                            created={answer.created}
                            questionId={slug}
                            answerId={answer.id}
                            setQuestion={setQuestion}
                          >
                            {answer.text}
                          </PostSummary>
                          <CommentList
                            questionId={slug}
                            answerId={answer.id}
                            setQuestion={setQuestion}
                            userInfoRedux={userInfoRedux}
                          >
                            {answer.comments.map(
                              ({ slug, author, created, body }) => (
                                <CommentItem
                                  key={slug}
                                  commentId={slug}
                                  questionId={slug}
                                  answerId={answer.id}
                                  author={author.username}
                                  isOwner={
                                    author.username === question.author.username
                                  }
                                  created={created}
                                  setQuestion={setQuestion}
                                >
                                  {body}
                                </CommentItem>
                              )
                            )}
                          </CommentList>
                        </PostWrapper>
                      ))}
                    </AnswerContainer>
                  )}

                  <AddAnswer
                    tags={question.tags}
                    slug={slug}
                    setQuestion={setQuestion}
                  />
                </>
              )}
            </DetailPageContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default QuestionDetail;
