import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import step from "../../../assets/images/step1-1.png";
import bgCircle from "assets/images/landing/Component 331.svg";
import company from "assets/images/landing/company.png";
import budget from "assets/images/landing/budget.png";
import satisfaction from "assets/images/landing/satisfaction.png";
import plugin from "assets/images/landing/plugin.png";
import AUTOESTIMATION from "assets/images/estimationWhat.png";

/// new features icons
import equipment from "assets/images/new-features/equipment.png";
import labor from "assets/images/new-features/labor.png";
import cost from "assets/images/new-features/cost.png";
import material from "assets/images/new-features/material.png";
import time from "assets/images/new-features/time.png";
// import bgCircle from 'assets/images/landing/Component 331.svg';

const FeaturesComponent = () => {
  return (
    <>
      <div className="overflowX-hidden w-100 mt-5">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4">
                <img
                  src={step}
                  alt=""
                  className="m-auto d-block text-center img-lead"
                />
                <img
                  src={AUTOESTIMATION}
                  alt=""
                  className="d-block text-center img-autoEstimate"
                />
              </div>
              <div className="col-lg-6 about-features">
                <h3 className="head-bidlight">
                  WHAT IS <b>BIDLIGHT AUTO ESTIMATIONS?</b>
                </h3>
                <p className="lead-bidlight-estimate">
                  BidLight 3.0 is an estimation platform powered by two
                  sophisticated classifiers (AI models): a metadata classifier
                  and a geometry classifier. These classifiers enable the system
                  to understand construction geometry and accurately match it to
                  the correct price items with an accuracy rate of 86%.
                  <br />
                  <br />
                  BidLight 3.0 has been trained on a large construction dataset,
                  which allows it to interpret Revit/IFC geometry and predict
                  costs associated with labor, equipment, materials, and time.
                  <br />
                  <br />
                  BidLight 3.0 is capable of working with all types of
                  construction data and geometries. It can classify your Bill of
                  Quantities (BOQ) automatically, without the need for human
                  intervention."
                </p>
              </div>
            </div>
          </div>
        </div>

        <section
          className="site-section bg-grey-300 bg-right-curve position-relative"
          id="features-section"
        >
          <img src={bgCircle} className="feature-shape-img" />
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-7 text-center mt-5 mb-5">
                {/* <h2 className="section-title">BidLight Features</h2> */}
                <p className="lead-bidlight-estimate">
                  Gain more clients, move fast, provide value, build trust and
                  show your degree of transparency instantly!
                </p>
              </div>
            </div>
            <div className="row align-items-stretch feature-row-width">
              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                //  data-aos="fade-up"
                // data-aos-delay="0"
              >
                <div className="unit-4 d-block">
                  <div className="unit-4-icon mb-3">
                    <span className="icon-wrap">
                      <span className="text-primary">
                        <img src={equipment} />
                      </span>
                    </span>
                  </div>
                  <div>
                    <h3>Equipment</h3>
                    <p>
                      Generate full list of the required equipment to build your
                      design. Share the equipment list, cost and time with your
                      client.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                // data-aos-delay="100"
                // data-aos="fade-up"
                //  data-aos-delay="0"
              >
                <div className="unit-4 d-block">
                  <div className="unit-4-icon mb-3">
                    <span className="icon-wrap">
                      <span className="text-primary">
                        <img src={labor} className="" />
                      </span>
                    </span>
                  </div>
                  <div>
                    <h3>Labor</h3>
                    <p>
                      Learn about the required labor to build your design. Share
                      the labor list, cost and time with your client.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                //  data-aos="fade-up"
                // data-aos-delay="0"
              >
                <div className="unit-4 d-block">
                  <div className="unit-4-icon mb-3">
                    <span className="icon-wrap">
                      <span className="text-primary">
                        {/* <FontAwesomeIcon icon={faBuilding} /> */}
                        <img src={material} className="" />
                      </span>
                    </span>
                  </div>
                  <div>
                    <h3>Material</h3>
                    <p>
                      Learn about the required material to build your design.
                      Including time required to handle and install.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                // data-aos-delay="200"
                // data-aos="fade-up"
                // data-aos-delay="0"
              >
                <div className="unit-4 d-block">
                  <div className="unit-4-icon mb-3">
                    <span className="icon-wrap">
                      <span className="text-primary">
                        <img src={time} className="" />
                      </span>
                    </span>
                  </div>
                  <div>
                    <h3>Time</h3>
                    <p>
                      Understand the total time required to build your design.
                      Including a breakdown by equipment and labor.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                //  data-aos="fade-up"
                //  data-aos-delay="0"
              >
                <div className="unit-4 d-block">
                  <div className="unit-4-icon mb-3">
                    <span className="icon-wrap">
                      <span className="text-primary">
                        {/* <FontAwesomeIcon icon={faFileInvoiceDollar} /> */}
                        <img src={budget} className="" />
                      </span>
                    </span>
                  </div>
                  <div>
                    <h3>Automatic Estimation</h3>
                    <p>
                      Complete project estimation with the ability to update the
                      cost in real time.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                // data-aos="fade-up"
                //  data-aos-delay="0"
              >
                <div className="unit-4 d-block">
                  <div className="unit-4-icon mb-3">
                    <span className="icon-wrap">
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faDollarSign} />
                      </span>
                    </span>
                  </div>
                  <div>
                    <h3>Updated Unit Cost</h3>
                    <p>
                      Access to our up to date database of unit costs. Ability
                      to add your own cost items.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FeaturesComponent;
