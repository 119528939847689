import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { RequestCard } from "./requestCard";
import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  getCompanyRoles,
  getRole,
  getAdminPermission,
  getUserCompanyPermission,
} from "api/permission/getPermissions";
import { createFeatureRequest } from "api/featureRequests/createFeatureRequest";
import { getFeatureRequest } from "api/featureRequests/getFeatureRequest";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import { setFeatureRequest } from "actions/user";
import { eventTrack } from "helpers/ga4Helper";

const Sidebar = () => {
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  const history = useHistory();
  const location = useLocation();
  const [requestModal, setRequestModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [loading, setLoading] = useState(false);
  const [featureRequests, setFeatureRequests] = useState([]);

  const handlenavigate = (url) => {
    history.push(url);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getFeatureRequest(userInfoRedux?.id);
        if (res)
          setFeatureRequests(res.map((feature) => feature.requestedFeature));
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const requestAccess = async () => {
    setLoading(true);
    const res = await createFeatureRequest(userInfoRedux.id, {
      companyId: userInfoRedux.company,
      permission: requestType,
      userId: userInfoRedux.id,
    });
    if (res.status === 200) {
      setLoading(false);
      dispatch(
        setFeatureRequest(res?.map((feature) => feature.requestedFeature))
      );
      setFeatureRequests(res?.map((feature) => feature.requestedFeature));
    }
  };

  const handleRequestType = (requestType) => {
    setRequestType(requestType);
    setRequestModal(true);
  };

  return (
    <>
      <div className="profile-sidebar">
        <p className="font-weight-bold">Settings</p>

        <div>
          {userInfoRedux?.admin == true && (
            <button
              onClick={() => handlenavigate("/main-admin")}
              className={
                location.pathname == "/main-admin" ? "ActiveTab" : null
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-flag-fill"
                viewBox="0 0 16 16"
              >
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
              </svg>
              Organization Settings
            </button>
          )}

          {userInfoRedux?.admin == true && (
            <button
              className={
                location.pathname == "/admin/affiliations" ? "ActiveTab" : null
              }
              onClick={() => {
                handlenavigate("/admin/affiliations");
                eventTrack(
                  "Navigate-Admin-Affiliations-Action",
                  "Navigate-Admin-Affiliations",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
            >
              <i class="fa fa-money mr-3"></i>
              Affiliations
            </button>
          )}

          {userInfoRedux?.admin == true && (
            <button
              onClick={() => {
                handlenavigate("/admin/support-chat");
                eventTrack(
                  "Navigate-Admin-SupportChat-Action",
                  "Navigate-Admin-SupportChat",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                );
              }}
              className={
                location.pathname == "/admin/support-chat" ? "ActiveTab" : null
              }
            >
              <i className="fa fa-comments mr-3"></i>
              Support Chat
            </button>
          )}

          <button
            onClick={() => {
              handlenavigate("/admin/company-settings");
              eventTrack(
                "Navigate-Admin-CompanySettings-Action",
                "Navigate-Admin-CompanySettings",
                userInfoRedux?.name,
                "+1",
                false,
                "+1"
              );
            }}
            className={
              location.pathname == "/admin/company-settings"
                ? "ActiveTab"
                : null
            }
          >
            <i className="fa fa-building mr-3"></i>
            Company Profile
          </button>

          {/* Projects */}
          <button
            className={
              location.pathname == "/admin/projects-settings"
                ? "ActiveTab"
                : null
            }
            onClick={() => {
              FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW)
                ? handlenavigate("/admin/projects-settings")
                : handleRequestType(ALL_PERMISSIONS.PROJECT_VIEW);
                eventTrack(
                  "Navigate-Admin-Project-Settings-Action",
                  "Navigate-Admin-Project-Settings",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                )
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-bag-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4z" />
            </svg>
            Projects Settings
            {FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW) ? null : (
              <p
                className="access-btn"
                onClick={() => {
                  handleRequestType(ALL_PERMISSIONS.PROJECT_VIEW);
                }}
              >
                {userInfoRedux?.companyAdmin
                  ? "Acquire License"
                  : "Request Access"}
              </p>
            )}
          </button>

          {/* Modal health settings */}
          <button
            onClick={() => {
              FinalPermissions?.includes(
                ALL_PERMISSIONS.MODAL_HEALTH_SETTINGS_VIEW
              )
                ? handlenavigate("/admin/model-health-settings")
                : handleRequestType(ALL_PERMISSIONS.MODAL_HEALTH_SETTINGS_VIEW);
                eventTrack(
                  "Navigate-Admin-Model-Health-Settings-Action",
                  "Navigate-Admin-Model-Health-Settings",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                )
            }}
            className={
              location.pathname == "/admin/model-health-settings"
                ? "ActiveTab"
                : null
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
            >
              <path
                d="M17 0C17.5523 0 18 0.44772 18 1V19C18 19.5523 17.5523 20 17 20H3C2.44772 20 2 19.5523 2 19V17H0V15H2V13H0V11H2V9H0V7H2V5H0V3H2V1C2 0.44772 2.44772 0 3 0H17ZM11 6H9V9H6V11H8.999L9 14H11L10.999 11H14V9H11V6Z"
                fill="black"
              />
            </svg>
            Model Health Settings
            {FinalPermissions?.includes(
              ALL_PERMISSIONS.MODAL_HEALTH_SETTINGS_VIEW
            ) ? null : (
              <p
                className="access-btn"
                onClick={() => {
                  handleRequestType(ALL_PERMISSIONS.MODAL_HEALTH_SETTINGS_VIEW);
                }}
              >
                {userInfoRedux?.companyAdmin
                  ? "Acquire License"
                  : "Request Access"}
              </p>
            )}
          </button>

          {/* Milestones */}
          <button
            onClick={() => {
              FinalPermissions?.includes(ALL_PERMISSIONS.MILESTONE_VIEW)
                ? handlenavigate("/admin/milestones")
                : handleRequestType(ALL_PERMISSIONS.MILESTONE_VIEW);
                eventTrack(
                  "Navigate-Admin-Milestones-Action",
                  "Navigate-Admin-Milestones",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                )
            }}
            className={
              location.pathname == "/admin/milestones" ? "ActiveTab" : null
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-flag-fill"
              viewBox="0 0 16 16"
            >
              <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
            </svg>
            Milestones
            {FinalPermissions?.includes(
              ALL_PERMISSIONS.MILESTONE_VIEW
            ) ? null : (
              <p
                className="access-btn"
                onClick={() => {
                  handleRequestType(ALL_PERMISSIONS.MILESTONE_VIEW);
                }}
              >
                {userInfoRedux?.companyAdmin
                  ? "Acquire License"
                  : "Request Access"}
              </p>
            )}
          </button>

          {/* Discipline */}
          <button
            onClick={() => {
              FinalPermissions?.includes(ALL_PERMISSIONS.DISCIPLINE_VIEW)
                ? handlenavigate("/admin/discipline-settings")
                : handleRequestType(ALL_PERMISSIONS.DISCIPLINE_VIEW);
                eventTrack(
                  "Navigate-Admin-Discipline-Settings-Action",
                  "Navigate-Admin-Discipline-Settings",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                )
            }}
            className={
              location.pathname == "/admin/discipline-settings"
                ? "ActiveTab"
                : null
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <g id="Iconly/Curved/Category">
                <g id="Category">
                  <path
                    id="Stroke 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.0003 6.6738C21.0003 8.7024 19.3551 10.3476 17.3265 10.3476C15.2979 10.3476 13.6536 8.7024 13.6536 6.6738C13.6536 4.6452 15.2979 3 17.3265 3C19.3551 3 21.0003 4.6452 21.0003 6.6738Z"
                    stroke="#130F26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Stroke 3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.3467 6.6738C10.3467 8.7024 8.7024 10.3476 6.6729 10.3476C4.6452 10.3476 3 8.7024 3 6.6738C3 4.6452 4.6452 3 6.6729 3C8.7024 3 10.3467 4.6452 10.3467 6.6738Z"
                    stroke="#130F26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Stroke 5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.0003 17.2619C21.0003 19.2905 19.3551 20.9348 17.3265 20.9348C15.2979 20.9348 13.6536 19.2905 13.6536 17.2619C13.6536 15.2333 15.2979 13.5881 17.3265 13.5881C19.3551 13.5881 21.0003 15.2333 21.0003 17.2619Z"
                    stroke="#130F26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Stroke 7"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.3467 17.2619C10.3467 19.2905 8.7024 20.9348 6.6729 20.9348C4.6452 20.9348 3 19.2905 3 17.2619C3 15.2333 4.6452 13.5881 6.6729 13.5881C8.7024 13.5881 10.3467 15.2333 10.3467 17.2619Z"
                    stroke="#130F26"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </svg>
            Discipline Settings
            {FinalPermissions?.includes(
              ALL_PERMISSIONS.DISCIPLINE_VIEW
            ) ? null : (
              <p
                className="access-btn"
                onClick={() => {
                  handleRequestType(ALL_PERMISSIONS.DISCIPLINE_VIEW);
                }}
              >
                {userInfoRedux?.companyAdmin
                  ? "Acquire License"
                  : "Request Access"}
              </p>
            )}
          </button>

          {/* Revision */}
          <button
            onClick={() => {
              FinalPermissions?.includes(ALL_PERMISSIONS.REVISION_VIEW)
                ? handlenavigate("/admin/revision-settings")
                : handleRequestType(ALL_PERMISSIONS.REVISION_VIEW);
                eventTrack(
                  "Navigate-Admin-Revision-Settings-Action",
                  "Navigate-Admin-Revision-Settings",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                )
            }}
            className={
              location.pathname == "/admin/revision-settings"
                ? "ActiveTab"
                : null
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="800px"
              height="800px"
              viewBox="-2 0 32 32"
              version="1.1"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Icon-Set-Filled"
                  transform="translate(-468.000000, -101.000000)"
                  fill="#000000"
                >
                  <path
                    d="M489,117 L475,117 C474.447,117 474,116.553 474,116 C474,115.448 474.447,115 475,115 L489,115 C489.553,115 490,115.448 490,116 C490,116.553 489.553,117 489,117 L489,117 Z M489,122 L475,122 C474.447,122 474,121.553 474,121 C474,120.448 474.447,120 475,120 L489,120 C489.553,120 490,120.448 490,121 C490,121.553 489.553,122 489,122 L489,122 Z M489,127 L475,127 C474.447,127 474,126.553 474,126 C474,125.448 474.447,125 475,125 L489,125 C489.553,125 490,125.448 490,126 C490,126.553 489.553,127 489,127 L489,127 Z M477,105 L479.223,105 C479.223,103.896 480.466,103 482,103 C483.534,103 484.777,103.896 484.777,105 L487,105 L487,109 L477,109 L477,105 L477,105 Z M492,105 L489,105 L489,103 L486.307,103 C485.44,101.81 483.846,101 482,101 C480.154,101 478.56,101.81 477.693,103 L475,103 L475,105 L472,105 C469.791,105 468,106.791 468,109 L468,129 C468,131.209 469.791,133 472,133 L492,133 C494.209,133 496,131.209 496,129 L496,109 C496,106.791 494.209,105 492,105 L492,105 Z"
                    id="clipboard"
                  ></path>
                </g>
              </g>
            </svg>
            Revision Settings
            {FinalPermissions?.includes(
              ALL_PERMISSIONS.REVISION_VIEW
            ) ? null : (
              <p
                className="access-btn"
                onClick={() => {
                  handleRequestType(ALL_PERMISSIONS.REVISION_VIEW);
                }}
              >
                {userInfoRedux?.companyAdmin
                  ? "Acquire License"
                  : "Request Access"}
              </p>
            )}
          </button>

          {/* User, roles and permission */}
          <button
            onClick={() => {
              FinalPermissions?.includes(ALL_PERMISSIONS.USER_VIEW)
                ? handlenavigate("/admin/user-permissions")
                : handleRequestType(ALL_PERMISSIONS.USER_VIEW);
                eventTrack(
                  "Navigate-Admin-User-Permissions-Action",
                  "Navigate-Admin-User-Permissions",
                  userInfoRedux?.name,
                  "+1",
                  false,
                  "+1"
                )
            }}
            className={
              location.pathname == "/admin/user-permissions"
                ? "ActiveTab"
                : null
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M15.2323 10.9609C14.3651 10.9609 13.5174 11.2181 12.7963 11.6999C12.0753 12.1817 11.5133 12.8664 11.1814 13.6676C10.8496 14.4688 10.7627 15.3504 10.9319 16.2009C11.1011 17.0515 11.5187 17.8327 12.1319 18.4459C12.7451 19.0591 13.5263 19.4767 14.3769 19.6459C15.2274 19.8151 16.109 19.7283 16.9102 19.3964C17.7114 19.0645 18.3962 18.5026 18.8779 17.7815C19.3597 17.0605 19.6169 16.2127 19.6169 15.3456C19.6108 14.1845 19.1469 13.0728 18.326 12.2519C17.505 11.4309 16.3933 10.967 15.2323 10.9609ZM16.0015 15.7302C15.8822 15.7272 15.7646 15.7011 15.6554 15.6532L14.0015 17.3071C13.9088 17.3989 13.7856 17.4536 13.6554 17.4609C13.5893 17.4673 13.5227 17.4565 13.462 17.4296C13.4014 17.4026 13.3487 17.3604 13.3092 17.3071C13.2165 17.208 13.1648 17.0774 13.1648 16.9417C13.1648 16.806 13.2165 16.6754 13.3092 16.5763L14.963 14.9225C14.9192 14.8119 14.8933 14.6951 14.8861 14.5763C14.8686 14.3929 14.8892 14.2079 14.9467 14.0329C15.0041 13.8578 15.0972 13.6966 15.2201 13.5593C15.3429 13.422 15.4928 13.3117 15.6604 13.2352C15.828 13.1586 16.0096 13.1177 16.1938 13.1148C16.3131 13.1177 16.4307 13.1438 16.54 13.1917C16.6169 13.1917 16.6169 13.2686 16.5784 13.3071L15.8092 14.0379C15.7914 14.0468 15.7764 14.0606 15.766 14.0776C15.7555 14.0945 15.75 14.1141 15.75 14.134C15.75 14.1539 15.7555 14.1735 15.766 14.1905C15.7764 14.2074 15.7914 14.2212 15.8092 14.2302L16.3092 14.7302C16.3229 14.7477 16.3404 14.762 16.3604 14.7717C16.3804 14.7815 16.4023 14.7866 16.4246 14.7866C16.4468 14.7866 16.4688 14.7815 16.4888 14.7717C16.5088 14.762 16.5263 14.7477 16.54 14.7302L17.2707 13.9994C17.3092 13.9609 17.4246 13.9609 17.4246 14.0379C17.4645 14.1496 17.4904 14.2659 17.5015 14.384C17.4986 14.5732 17.4566 14.7598 17.3782 14.9321C17.2997 15.1043 17.1865 15.2585 17.0457 15.3849C16.9048 15.5113 16.7394 15.6072 16.5597 15.6666C16.38 15.7261 16.1899 15.7477 16.0015 15.7302Z"
                fill="#1D1D1D"
              />
              <path
                d="M8.84826 11.1926C11.5884 11.1926 13.8098 8.97125 13.8098 6.23107C13.8098 3.49089 11.5884 1.26953 8.84826 1.26953C6.10808 1.26953 3.88672 3.49089 3.88672 6.23107C3.88672 8.97125 6.10808 11.1926 8.84826 11.1926Z"
                fill="#1D1D1D"
              />
              <path
                d="M10.1931 19.652C11.0392 19.652 10.5777 19.0751 10.5777 19.0751C9.72848 18.0173 9.267 16.7007 9.26998 15.3443C9.2659 14.4941 9.44978 13.6536 9.80844 12.8827C9.82459 12.8387 9.85095 12.7992 9.88537 12.7674C10.1546 12.2289 9.61614 12.1904 9.61614 12.1904C9.37393 12.1585 9.12959 12.1456 8.88537 12.152C7.07455 12.159 5.3262 12.8147 3.9573 14.0002C2.58841 15.1856 1.6896 16.8223 1.42383 18.6135C1.42383 18.9981 1.53921 19.6904 2.73152 19.6904H10.0777C10.1546 19.652 10.1546 19.652 10.1931 19.652Z"
                fill="#1D1D1D"
              />
            </svg>
            Users, Roles & Permissions
            {FinalPermissions?.includes(ALL_PERMISSIONS.USER_VIEW) ? null : (
              <p
                className="access-btn"
                onClick={() => {
                  handleRequestType(ALL_PERMISSIONS.USER_VIEW);
                }}
              >
                {userInfoRedux?.companyAdmin
                  ? "Acquire License"
                  : "Request Access"}
              </p>
            )}
          </button>

          {/* Export config */}
          <button
            onClick={() => {
              FinalPermissions?.includes(
                ALL_PERMISSIONS.PROJECT_EXPORT_SETTINGS_VIEW
              )
                ? handlenavigate("/admin/export-settings")
                : handleRequestType(
                    ALL_PERMISSIONS.PROJECT_EXPORT_SETTINGS_VIEW
                  );
                  eventTrack(
                    "Navigate-Admin-Export-Settings-Action",
                    "Navigate-Admin-Export-Settings",
                    userInfoRedux?.name,
                    "+1",
                    false,
                    "+1"
                  )
            }}
            className={
              location.pathname == "/admin/export-settings" ? "ActiveTab" : null
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.95401 0.211087C9.2876 -0.0680735 10.6807 -0.0768834 12.0449 0.208337C12.2219 1.36789 12.9048 2.43941 13.9997 3.07152C15.0945 3.70362 16.364 3.75933 17.4566 3.33279C18.3858 4.37167 19.0747 5.58252 19.4997 6.87701C18.5852 7.61007 17.9997 8.73649 17.9997 9.99969C17.9997 11.2637 18.5859 12.3907 19.5013 13.1237C19.29 13.7641 19.0104 14.3927 18.6599 14.9997C18.3094 15.6068 17.9049 16.1632 17.4559 16.6664C16.3634 16.2401 15.0943 16.296 13.9997 16.9279C12.9057 17.5595 12.223 18.6299 12.0453 19.7884C10.7118 20.0675 9.3187 20.0763 7.95443 19.7911C7.77748 18.6316 7.09451 17.56 5.99967 16.9279C4.90484 16.2958 3.63539 16.2401 2.54272 16.6667C1.61357 15.6278 0.924657 14.4169 0.499637 13.1224C1.41412 12.3894 1.99968 11.2629 1.99968 9.99969C1.99968 8.73579 1.41344 7.60876 0.498047 6.87573C0.709327 6.23531 0.988937 5.60678 1.33942 4.99972C1.68991 4.39266 2.09443 3.83625 2.54341 3.33306C3.63593 3.7593 4.90507 3.70349 5.99967 3.07152C7.09364 2.43991 7.7764 1.36959 7.95401 0.211087ZM9.9997 12.9997C11.6565 12.9997 12.9997 11.6566 12.9997 9.99969C12.9997 8.34289 11.6565 6.99972 9.9997 6.99972C8.3428 6.99972 6.99967 8.34289 6.99967 9.99969C6.99967 11.6566 8.3428 12.9997 9.9997 12.9997Z"
                fill="#3A5765"
              />
              <path
                d="M7.95401 0.211087C9.2876 -0.0680735 10.6807 -0.0768834 12.0449 0.208337C12.2219 1.36789 12.9048 2.43941 13.9997 3.07152C15.0945 3.70362 16.364 3.75933 17.4566 3.33279C18.3858 4.37167 19.0747 5.58252 19.4997 6.87701C18.5852 7.61007 17.9997 8.73649 17.9997 9.99969C17.9997 11.2637 18.5859 12.3907 19.5013 13.1237C19.29 13.7641 19.0104 14.3927 18.6599 14.9997C18.3094 15.6068 17.9049 16.1632 17.4559 16.6664C16.3634 16.2401 15.0943 16.296 13.9997 16.9279C12.9057 17.5595 12.223 18.6299 12.0453 19.7884C10.7118 20.0675 9.3187 20.0763 7.95443 19.7911C7.77748 18.6316 7.09451 17.56 5.99967 16.9279C4.90484 16.2958 3.63539 16.2401 2.54272 16.6667C1.61357 15.6278 0.924657 14.4169 0.499637 13.1224C1.41412 12.3894 1.99968 11.2629 1.99968 9.99969C1.99968 8.73579 1.41344 7.60876 0.498047 6.87573C0.709327 6.23531 0.988937 5.60678 1.33942 4.99972C1.68991 4.39266 2.09443 3.83625 2.54341 3.33306C3.63593 3.7593 4.90507 3.70349 5.99967 3.07152C7.09364 2.43991 7.7764 1.36959 7.95401 0.211087ZM9.9997 12.9997C11.6565 12.9997 12.9997 11.6566 12.9997 9.99969C12.9997 8.34289 11.6565 6.99972 9.9997 6.99972C8.3428 6.99972 6.99967 8.34289 6.99967 9.99969C6.99967 11.6566 8.3428 12.9997 9.9997 12.9997Z"
                fill="black"
                fillOpacity="0.2"
              />
            </svg>
            Export Settings
            {FinalPermissions?.includes(
              ALL_PERMISSIONS.PROJECT_EXPORT_SETTINGS_VIEW
            ) ? null : (
              <p
                className="access-btn"
                onClick={() => {
                  handleRequestType(
                    ALL_PERMISSIONS.PROJECT_EXPORT_SETTINGS_VIEW
                  );
                }}
              >
                {userInfoRedux?.companyAdmin
                  ? "Acquire License"
                  : "Request Access"}
              </p>
            )}
          </button>
        </div>
      </div>

      <Modal
        title={
          userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
        }
        open={requestModal}
        footer={null}
        onCancel={() => setRequestModal(false)}
      >
        <RequestCard
          status={featureRequests?.includes(requestType)}
          loading={loading}
          icon={
            featureRequests?.includes(requestType) ? (
              <ClockCircleOutlined style={{ color: "black" }} />
            ) : (
              <InfoCircleOutlined style={{ color: "black" }} />
            )
          }
          title={
            featureRequests?.includes(requestType)
              ? `Request Already Sent`
              : `Oops, looks like you need to ${
                  userInfoRedux?.companyAdmin
                    ? `Acquire License`
                    : `Request Access`
                }`
          }
          description={
            featureRequests?.includes(requestType)
              ? `Please reach out to your project Admin`
              : `Elevate your experience with exclusive futures, early releases, and premium features.`
          }
          buttonText={
            userInfoRedux?.companyAdmin ? "Acquire License" : "Request Access"
          }
          buttonHandler={() => requestAccess()}
        />
      </Modal>
    </>
  );
};

export default Sidebar;
