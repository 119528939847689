import React, { useEffect, useState } from "react";
import { publicFetch } from "../../util/fetcher";

import Layout from "../../components/layout";
import PageTitle from "../../components/page-title";
import SearchInput from "../../components/search-input";
import UserList from "../../components/user-list";
import UserItem from "../../components/user-list/user-item";
import { Spinner } from "../../components/icons";
import { extractImageFromZip } from "../../utils/imageHandler";
import { HeadProvider } from "react-head";
import Sidebar from "learning/components/layout/sidebar";

function UsersPage({ anonymous, userInfoRedux }) {
  const [searchTerm, setSearchTerm] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchTerm === null) {
      const fetchUser = async () => {
        const { data } = await publicFetch.get("/users");
        const allUsers = await Promise.all(
          data.map(async (user) => {
            user.profilePhoto = await extractImageFromZip(user.profilePhoto);
            return user;
          })
        );
        setUsers(allUsers);
      };

      fetchUser();
    } else {
      const delayDebounceFn = setTimeout(async () => {
        setLoading(true);
        const { data } = await publicFetch.get(
          searchTerm ? `/users/${searchTerm}` : `/users`
        );
        setUsers(data);

        const allUsers = await Promise.all(
          data.map(async (user) => {
            user.profilePhoto = await extractImageFromZip(user.profilePhoto);
            return user;
          })
        );
        setUsers(allUsers);
        setLoading(false);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  return (
    <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}>
      <HeadProvider>
        <title>Users - BidLight Learning</title>
      </HeadProvider>

      <div className="personal-area min-h-full">
        <div className="container-fluid learning-container-flex pt-3">
          <Sidebar />
          <div className="learning-content">
            <PageTitle title="Users" borderBottom={false} />

            <SearchInput
              placeholder="Search by user"
              isLoading={loading}
              autoFocus={false}
              autoComplete="off"
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {!users && (
              <div className="loading">
                <Spinner />
              </div>
            )}

            {users && (
              <>
                <UserList>
                  {users?.map((item) => (
                    <UserItem
                      key={item?._id}
                      username={item?.username}
                      profilePhoto={item?.profilePhoto}
                      created={item?.createdAt}
                      lastlogin={item?.lastlogin}
                      name={item?.name}
                      lastName={item?.lastName}
                    />
                  ))}
                </UserList>

                {users.length == 0 && (
                  <p className="not-found">No users matched your search.</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default UsersPage;
