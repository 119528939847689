const { url } = require("api");

module.exports = {
  title: 'BidLight BIM Revit Plugin Support & Learning',
  description: 'BidLight BIM Revit Plugin Support & Learning',
  siteUrl: url,
  author: {
    email: 'support@bidlight.com',
    name: 'BidLight Support'
  },
  lang: 'en'
}
