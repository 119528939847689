// export const url = "http://localhost:3300";
export const curlightUrl = "https://predeployment.curlight.com";
 export const url = "https://predeployment.bidlight.com"; //this ones
// export const url = "https://app.bidlight.com"; //this one

// export const urlWS = 'wss://app.bidlight.com';
export const urlWS = 'wss://predeployment.bidlight.com';

//export const url = "http://localhost:3300";
// export const IFC_Url = "https://ifc.bidlight.com"; // production url
export const IFC_Url = "https://preifc.bidlight.com"; // predeployment url
// export const IFC_Url = "http://localhost:4000"; // development local url

export const tokenRef = `${url.split('//')[1].split('.')[0]}${"_"}${"token"}`;
export const user_permissionsRef = `${url.split('//')[1].split('.')[0]}${"_"}${"user_permissions"}`;
export const company_permissionsRef = `${url.split('//')[1].split('.')[0]}${"_"}${"company_permissions"}`;
export const all_permissionsRef = `${url.split('//')[1].split('.')[0]}${"_"}${"all_permissions"}`;

export const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
    "User-Agent": "Bidlight-Web-Deployment",

    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",

    Authorization: null,
  },
};

export const compressedOptions = {
  headers: {
    "Content-Type": "application/gzip",
    "content-encoding": "gzip",
    "User-Agent": "Bidlight-Web-Deployment",

    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",

    Authorization: null,
  },
};

export function setJWT(token) {
  defaultOptions.headers.Authorization = "Bearer " + token;
  compressedOptions.headers.Authorization = "Bearer " + token;
}

export function post(endpoint, body, formData = false, isCurlight = false) {
  // console.log(defaultOptions.Authorization);
  // setJWT(localStorage.bidlight_token)
  const baseUrl = endpoint.startsWith("/ifc") ? IFC_Url : url;
  const formedUrl = isCurlight ? curlightUrl : baseUrl;
  return fetch(`${formedUrl}${endpoint}`, {
    method: "POST",
    body: formData ? body : JSON.stringify(body),
    headers: formData
      ? {
          Authorization: defaultOptions.headers.Authorization,
        }
      : defaultOptions.headers,
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
}

export function globalPut(endpoint, body, formData = false) {
  const baseUrl = endpoint.startsWith("/ifc") ? IFC_Url : url;
  return fetch(`${baseUrl}${endpoint}`, {
    method: "PUT",
    headers: formData
      ? {
          Authorization: defaultOptions.headers.Authorization,
        }
      : defaultOptions.headers,
    body: formData ? body : JSON.stringify(body),
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
}

export function get(endpoint, isCurlight = false) {
  const baseUrl = isCurlight ? curlightUrl : url;
  return fetch(`${baseUrl}${endpoint}`, {
    method: "GET",
    mode: "cors",
    headers: endpoint.endsWith("json.gz") ? undefined : defaultOptions.headers,
  }).then(async (res) => {
    return await res.json();
  });
}

export function getWithoutURL(endpoint) {
  return fetch(`${endpoint}`, {
    method: "GET",
    mode: "cors",
    headers: endpoint.endsWith("json.gz") ? undefined : defaultOptions.headers,
  }).then(async (res) => {
    return await res.json();
  });
}
export function getCompressed(endpoint, isIfcProject) {
  const baseUrl = isIfcProject ? IFC_Url : url;
  return fetch(`${baseUrl}${endpoint}`, {
    method: "GET",
    mode: "cors",
    headers: endpoint.endsWith("json.gz")
      ? undefined
      : compressedOptions.headers,
  }).then(async (res) => {
    return await res.json();
  });
}

export function remove(endpoint, object, isCurlight = false) {
  const baseUrl = isCurlight ? curlightUrl : url;
  return fetch(`${baseUrl}${endpoint}`, {
    method: "DELETE",
    headers: defaultOptions.headers,
    body: JSON.stringify(object),
  }).then(async (res) => {
    return await res.json();
  });
}

export function putGeneral(endpoint, object) {
  return fetch(`${url}${endpoint}`, {
    method: "PUT",
    headers: defaultOptions.headers,
    body: JSON.stringify(object),
  }).then(async (res) => {
    return await res.json();
  });
}

export function put(id, body, formData) {
  return fetch(`${url}/timelines?id=${id}`, {
    method: "PUT",
    headers: !formData
      ? {
          Authorization: defaultOptions.headers.Authorization,
        }
      : defaultOptions.headers,
    body: !formData ? body : JSON.stringify(body),
  }).then(async (res) => {
    return await res.json();
  });
}

export function putCatalog(endpoint, object) {
  return fetch(`${curlightUrl}${endpoint}`, {
    method: "PUT",
    headers: defaultOptions.headers,
    body: JSON.stringify(object),
  }).then(async (res) => {
    return await res.json();
  });
}

export function putProject(id, body, formData) {
  return fetch(`${url}/projects?_id=${id}`, {
    //?_id=${id}
    method: "PUT",
    headers: !formData
      ? {
          Authorization: defaultOptions.headers.Authorization,
        }
      : defaultOptions.headers,
    body: !formData ? body : JSON.stringify(body),
  }).then(async (res) => {
    return await res.json();
  });
}

export function putUser(id, body, formData) {
  return fetch(`${url}/users`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: defaultOptions.headers,
  }).then(async (res) => {
    return await res.json();
  });
}

export function putMilestone(body) {
  return fetch(`${url}/milestones`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: defaultOptions.headers,
  }).then(async (res) => {
    return await res.json();
  });
}

export function removeBulkUsers(endpoint, object) {
  return fetch(`${url}${endpoint}`, {
    method: "DELETE",
    headers: defaultOptions.headers,
    body: JSON.stringify(object),
  }).then(async (res) => {
    console.log("res--------", res);
    return await res;
  });
}

export function removeBulkMile(endpoint, object) {
  return fetch(`${url}${endpoint}`, {
    method: "DELETE",
    headers: defaultOptions.headers,
    body: JSON.stringify(object),
  }).then(async (res) => {
    return await res.json();
  });
}

export function postpro(endpoint, body, formData = false) {
  // console.log(defaultOptions.Authorization);
  // setJWT(localStorage.bidlight_token)
  const baseUrl = url;
  return fetch(`${baseUrl}${endpoint}`, {
    method: "POST",
    body: formData ? body : JSON.stringify(body),
    headers: formData
      ? {
          Authorization: defaultOptions.headers.Authorization,
        }
      : defaultOptions.headers,
  }).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }
    return await res.json();
  });
}

export function postCustomUrl(endpoint, body, formData = false) {
  return fetch(`${endpoint}`, {
    method: "PUT",
    timeout: 'infinte',
    body: formData ? body : JSON.stringify(body),
  }).then(async (res) => {
    if (!res.ok) {
      throw await res
    }
    return await res
  });
}
