import React, { useState } from "react";
import What from "assets/images/Intersection3.png";
import Partners from "assets/images/landing/partner llogo.png";
import "./WhatisBidlightComponent.css";
import { faCalendarCheck, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-scroll";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { reviewsData } from "components/LandingPageTemplateComponents/Pricing/PricingComponentData";
import star from "assets/images/star.png";
import arrowforward from "assets/images/arrow-forward-circle-outline.png";
import PlayButton from "assets/images/big-play-button.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 0,
  },
};
const WhatIsBidlightComponent = () => {
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    return (
      <div className="carousel-button-group custome-home-review">
        <div
          className={
            currentSlide === totalItems - slidesToShow
              ? "disable"
              : "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
          }
        >
          <img
            src={arrowforward}
            width={20}
            height={20}
            onClick={() => next()}
          />
        </div>
      </div>
    );
  };

  const ButtonGroup12 = () => {
    var video = document.getElementById("videoIdEstimation");
    var playicon = document.getElementById("playicon");
    playicon.style.display = "none";
    video.src =
      "https://www.youtube.com/embed/I6mksAdWRCU?autoplay=1&start=1&controls=1&mute=0&showinfo=0&rel=0&loop=1&playlist=I6mksAdWRCU";
  };

  return (
    <>
      <div
        className="site-blocks-cover pt-0 mt-0"
        style={{ overflow: "hidden" }}
        id="whatisbidlight"
      >
        <div className="container">
          <div
            className="row"
            data-aos="fade-up"
            data-aos-delay="200"
            style={{ justifyContent: "center", marginTop: "60px" }}
          >
            <div className="col-lg-6 d-flex justify-content-between flex-column">
              <h1 className="heading-bidlight">3D Model Based Estimation</h1>
              <big className="heading-bidlight">
                Labor, Equipment and Material
              </big>
              <p className="mb-5 lead">
                Predict the labor, equipment and material cost and time required
                for your BIM model
              </p>
              {/* <div className="homeButtons">
                <Link
                  className="btn btn-primary-custom d-flex align-items-center d-flex justify-content-between"
                  to="pricing-section"
                  style={{ backgroundColor: "#71A0B8" }}
                  spy={true}
                  smooth={true}
                  duration={1500}
                  offset={-400}
                >
                  Download
                  <FontAwesomeIcon className="font-icon" icon={faDownload} />
                </Link>
                <Link
                  className="btn btn-primary-custom d-flex align-items-center d-flex justify-content-between"
                  to="contact-section"
                  style={{ backgroundColor: "#71A0B8" }}
                  spy={true}
                  smooth={true}
                  duration={1500}
                  offset={-1000}
                >
                  Request a Demo
                  <FontAwesomeIcon
                    className="font-icon"
                    icon={faCalendarCheck}
                  />
                </Link>
              </div> */}
              <div>
                <Carousel
                  responsive={responsive}
                  infinite
                  autoPlay
                  arrows={false}
                  showDots
                  dotListClass="custom-dot-list-style custom-dot-list-style-home"
                  className="carousel-track-custom-home"
                  customButtonGroup={<ButtonGroup />}
                  height={500}
                >
                  {reviewsData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="bg-transparent rounded-lg mx-1 p-3 text-left"
                      >
                        <div className="d-flex justify-content-between">
                          <div className="d-flex justify-content-start">
                            <div>
                              <img
                                src={item.image}
                                alt=""
                                width={50}
                                height={50}
                                className="rounded-circle mr-3"
                              />
                            </div>
                            <div>
                              <h4 className="mb-1">{item.company}</h4>
                              <span className="mb-0 mt-auto">
                                <strong>{item.user}</strong>
                              </span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start align-items-center">
                            <span className="mr-2">5.0</span>
                            <img src={star} alt="" width={25} height={25} />
                          </div>
                        </div>
                        <div className="mt-4">
                          <p className="mb-0">{item.review}</p>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapperEstimation">
                <embed
                  id="videoIdEstimation"
                  allow="autoplay"
                  allowFullScreen
                  src={
                    "https://www.youtube.com/embed/I6mksAdWRCU?autoplay=1&controls=0&mute=1&showinfo=0&rel=0&loop=1&playlist=I6mksAdWRCU"
                  }
                ></embed>
                <div
                  id="playicon"
                  className="playpauseEstimation"
                  onClick={() => ButtonGroup12()}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatIsBidlightComponent;
