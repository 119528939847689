import {Elements} from '@stripe/react-stripe-js';
import React from 'react';
import CheckoutForm from './CheckoutForm';
import { stripePromise } from './stripeConfig';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function ElementsProvider() {
    // const stripePromise = loadStripe(
    //   "pk_test_51KtnDTSCPeNCmTapgkNgNEb6U2HOqmC7bMK88AWE2QFZYfYBjg2JcfGRyeJW1I7lC4522FX02oFUxA74gnlVuLhv001VAeQSqD"
    // );
    // const options = {
    //   // passing the client secret obtained from the server
    //   clientSecret:
    //     "sk_test_51KtnDTSCPeNCmTapyOjZgdIQwvyGW2pocD3k7zHktC2CyPcxC3XuK94uttYZ7couA8UN3mFLCq1rqCp7tF6BWFqo008Piubs4M",
    // };

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default ElementsProvider;