import React from "react";
import Feed from "../../components/articles/Feed";
import Seo from "../../components/seo/Seo";

import { eventTrack } from "../../components/utils";
import GA4 from "../../components/GA4";
import Layout from "learning/components/layout";
import { url } from "api";

const FeedPage = ({ anonymous, userInfoRedux }) => {
  return (
    <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}> 
      <Seo
        title={"BidLight BIM Revit Plugin Articles - BidLight Learning"}
        siteUrl={`${url}/learning/articles`}
      />
      {eventTrack("Article Feed", "Article Feed", null, 10, false)}
      <GA4
        userId={"Article Feed"}
        category={"Article Feed"}
        action={"Browsing Articles"}
        label={"Article Feed"}
        value={10}
        nonInteraction={false}
        transport={"xhr"}
      ></GA4>
      <Feed userInfoRedux={userInfoRedux}/>
    </Layout>
  );
};

export default FeedPage;
