import React, { useState, useEffect } from "react";
import { publicFetch } from "../util/fetcher";

import Layout from "../components/layout";
import QuestionWrapper from "../components/question/question-wrapper";
import QuestionStats from "../components/question/question-stats";
import QuestionSummary from "../components/question/question-summary";
import PageTitle from "../components/page-title";
import ButtonGroup from "../components/button-group";
import { Spinner } from "../components/icons";
import Seo from "../components/seo/Seo";
import Sidebar from "learning/components/layout/sidebar";
import Extra from "learning/components/layout/extra";
import { url } from "api";

const HomePage = ({ anonymous, userInfoRedux, query }) => {
  const [questions, setQuestions] = useState(null);
  const [sortType, setSortType] = useState("Votes");

  useEffect(() => {
    const fetchQuestion = async () => {
      const { data } = await publicFetch.get("/question");
      setQuestions(data);
    };

    const fetchQuestionByTag = async () => {
      const { data } = await publicFetch.get(
        `/questions/${query}`
      );
      setQuestions(data);
    };

    if (query) {
      fetchQuestionByTag();
    } else {
      fetchQuestion();
    }
  }, []);

  const handleSorting = () => {
    switch (sortType) {
      case "Votes":
        return (a, b) => b.votes.length - a.votes.length;
      case "Views":
        return (a, b) => b.views - a.views;
      case "Newest":
        return (a, b) => new Date(b.created) - new Date(a.created);
      case "Oldest":
        return (a, b) => new Date(a.created) - new Date(b.created);
      default:
        break;
    }
  };

  return (
    <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}>
      <Seo
        title={
          query
            ? `Question ${query} BidLight BIM Revit Plugin`
            : "BidLight BIM Revit Plugin Questions"
        }
        description={
          query
            ? `Question ${query} BidLight BIM Revit Plugin`
            : "BidLight BIM Revit Plugin Questions"
        }
        siteUrl={
          query
            ? `${url}/api/?tag=${query}`
            : `${url}`
        }
        keywords={
          query
            ? ` BidLight BIM Revit Plugin ${query}`
            : "BidLight BIM Revit Plugin Questions"
        }
        authorUsername={"BidLight"}
      />
      <div className="personal-area min-h-full">
        <div className="container-fluid pt-3 learning-container-flex">
          <Sidebar />
          <div className="learning-content">
            <div>
              <PageTitle
                title={
                  query
                    ? `Questions tagged [${query}]`
                    : "All Questions"
                }
                button
                borderBottom={false}
              />
              <div className="d-flex justify-content-end">
                <ButtonGroup
                  borderBottom
                  buttons={["Votes", "Views", "Newest", "Oldest"]}
                  selected={sortType}
                  setSelected={setSortType}
                />
              </div>

              {!questions && (
                <div className="loading">
                  <Spinner />
                </div>
              )}

              {questions
                ?.sort(handleSorting())
                .map(
                  ({
                    id,
                    votes,
                    answers,
                    views,
                    title,
                    text,
                    tags,
                    author,
                    created,
                  }) => (
                    <QuestionWrapper key={id}>
                      <QuestionStats
                        voteCount={votes.length}
                        answerCount={answers.length}
                        view={views}
                      />
                      <QuestionSummary
                        id={id}
                        title={title}
                        tags={tags}
                        author={author}
                        createdTime={created}
                      >
                        {text}
                      </QuestionSummary>
                    </QuestionWrapper>
                  )
                )}
            </div>
          </div>
          <Extra />
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
