import React, { useMemo, useState, useEffect } from "react";
import { useLocation, Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PersonalArea from "pages/PersonalArea";
import TimelinesPagePersonalArea from "pages/TimelinesPagePersonalArea";
import Viewer from "pages/TimelineViewer";
import SocketViewer from "pages/SocketViewer";
import Admin from "pages/Admin";
import UserProjects from "pages/UserProjects";
import TimelinePageAdmin from "pages/TimelinesPageAdmin";
import MainAdmin from "pages/MainAdmin";
import MainAdminProjects from "pages/MainAdminProjects";
import Authorization from "pages/Authorization";
import RegistrationUserAdmin from "pages/RegistrationUserAdmin";
import RegistrationUser from "pages/RegistrationUser";
import ResetPassword from "pages/ResetPassword";
import Share from "pages/Share";
// import LandingPageTemplate from "pages/LandingTemplate";
import Privacy from "pages/Privacy";
import RevitEstimate from "pages/RevitEstimate";

import { Maybe } from "helpers/maybeFunctor";
import "../pages/LandingTemplate/LandingTemplate.scss";

import "./App.scss";
import Header from "../components/Header";
import { MDBBox } from "mdbreact";
import DownloadSoftwareModal from "../components/DownloadSoftwareModal";
import { googleAnalytics4 } from "../helpers/ga4Helper";
import Thankyou from "pages/Thankyou";
import Projects from "pages/configuration/projects";
import PricingCatelogs from "pages/PricingCatelogs";
import Users from "pages/configuration/users";
import Milestones from "pages/configuration/milestones";
import Permissions from "pages/configuration/permisions";
import RequestAccess from "pages/configuration/requestAccess";
import Profile from "pages/Profile";
import UserPermissions from "pages/configuration/user_permissions";
import ModalHealthSetting from "pages/configuration/ModalHealth";
import ModalHealth from "pages/ModalHealth";
import ModalFileList from "pages/ModalHealth/ModalFileList/ModalFileList";
import ExportSettings from "pages/configuration/exportSettings";
import Report from "pages/ModalHealth/Report";
import Parameters from "pages/Parameters";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import ModelViewer from "pages/ModelViewer";
import MainAdminPermissions from "pages/MainAdmin/MainAdminPermissions";
import { setSidebarStatus } from "actions/user";
import AssetManagement from "pages/AssetManagement";
import AssetViewer from "pages/AssetManagement/AssetViewer";
import EstimationTemplate from "pages/EstimationTemplate";
import FilterView from "pages/FilterView";
import DocControl from "pages/DocControl";
import DisciplineSettings from "pages/configuration/DisciplineSettings";
import RevisionSettings from "pages/configuration/RevisionSettings";
import Dashboard from "pages/Dashboard";
import ResourceTracking from "pages/ResourceTracking";
import MyApp from "learning/pages/_app";
import { tokenRef } from "api";
import ActivityLog from "pages/ActivityLog/ActivityLog";
import Earnings from "pages/Earnings";
import Affiliations from "pages/configuration/Affiliations";
import CompanySettings from "pages/configuration/CompanySettings";
import { SupportChat } from "pages/Chat/SupportChat";

const App = (props) => {
  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));
  const maybeEmail = Maybe.of(userInfoRedux).map(
    (userInfo) => userInfo.email
  ).value;

  const maybeUserID = Maybe.of(userInfoRedux).map(
    (userInfo) => userInfo.id
  ).value;
  const maybeMainAdmin = Maybe.of(userInfoRedux).map(
    (userInfo) => userInfo.admin
  ).value;
  const maybeAdmin = Maybe.of(userInfoRedux).map(
    (userInfo) => userInfo.companyAdmin
  ).value;

  const adminFlag = maybeMainAdmin || maybeAdmin;
  const [modal, setModal] = useState("");

  const location = useLocation();
  const socketviewer = location.pathname.startsWith("/socketviewer");

  useEffect(() => {
    dispatch(setSidebarStatus(false));
  }, [location]);

  useEffect(() => {
    // LinkedIn Partner ID
    const partnerId = "3442420";

    // Add LinkedIn Insight Tag to the page
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      _linkedin_partner_id = "${partnerId}";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;
    document.head.appendChild(script);

    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    script2.async = true;
    document.head.appendChild(script2);
  }, []);

  const pages = useMemo(() => {
    return (
      <div style={{ margin: "auto" }}>
        <Switch>
          <Route path="/privacy" exact component={Privacy} />
          <Route
            path="/Revit-Estimate-Plugin"
            exact
            component={RevitEstimate}
          />

          <Route
            path="/socketviewer/:projectId/:wsSessionId/:token/:companyId"
            exact
            component={({ match }) => {
              return (
                <SocketViewer
                  companyId={match.params.companyId}
                  projectId={match.params.projectId}
                  wsSessionId={match.params.wsSessionId}
                  token={match.params.token}
                />
              );
            }}
          />
          <Route path="/dashboard" component={Dashboard} exact />
          <Route path="/personal-area" exact component={PersonalArea} />
          <Route path="/share-project/:shareId" exact component={Share} />
          <Route
            path="/personal-area/projects/:projectId/timelines"
            exact
            component={({ match }) => {
              const { projectId } = match.params;
              return <TimelinesPagePersonalArea projectId={projectId} />;
            }}
          />
          <Route
            path="/personal-area/projects/:projectId/timelines/:timelineId"
            exact
            component={({ match }) => {
              return (
                <Viewer
                  projectId={match.params.projectId}
                  timelineId={match.params.timelineId}
                  // token={match.params.token}
                  personal={true}
                />
              );
            }}
          />
          <Route
            path="/personal-area/projects/:projectId/timelines/:timelineId/:token"
            exact
            component={({ match }) => {
              return (
                <Viewer
                  projectId={match.params.projectId}
                  timelineId={match.params.timelineId}
                  token={match.params.token}
                  personal={true}
                />
              );
            }}
          />
          <Route
            path="/personal-area/projects/model-view/:projectId"
            exact
            component={({ match }) => {
              return <ModelViewer projectId={match.params.projectId} />;
            }}
          />

          <Route
            path="/main-admin/projects/:companyId/model-view/:projectId"
            exact
            component={({ match }) => {
              return <ModelViewer projectId={match.params.projectId} companyId={match.params.companyId} />;
            }}
          />

          {adminFlag ? (
            <Route
              path="/main-admin/users/:companyId"
              component={({ match }) => {
                return <Admin companyId={match.params.companyId} />;
              }}
              exact
            />
          ) : null}
          {adminFlag ? (
            <Route
              exact
              path="/main-admin/users/:companyId/:idUser"
              component={({ match }) => {
                const { idUser } = match.params;
                return <UserProjects idUser={idUser} />;
              }}
            />
          ) : null}
          {adminFlag ? (
            <Route
              path="/main-admin/users/:companyId/:idUser/:project"
              exact
              component={({ match }) => {
                const { project } = match.params;
                return <TimelinePageAdmin projectId={project} />;
              }}
            />
          ) : null}
          {maybeMainAdmin ? (
            <Route path="/main-admin" component={MainAdmin} exact />
          ) : null}
          {maybeMainAdmin ? (
            <Route
              path="/main-admin/projects/:idCompany"
              component={MainAdminProjects}
              exact
            />
          ) : null}
          {maybeMainAdmin ? (
            <Route
              path="/main-admin/permissions/:idCompany"
              component={MainAdminPermissions}
              exact
            />
          ) : null}

          {maybeMainAdmin ? (
            <Route path="/admin/affiliations" component={Affiliations} exact />
          ) : null}
          {FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW) ? (
            <Route path="/admin/projects-settings" component={Projects} exact />
          ) : null}

          {FinalPermissions?.includes(ALL_PERMISSIONS.USER_VIEW) ? (
            <Route path="/users" component={Users} exact />
          ) : null}
          {FinalPermissions?.includes(ALL_PERMISSIONS.MILESTONE_VIEW) ? (
            <Route path="/admin/milestones" component={Milestones} exact />
          ) : null}
          {FinalPermissions?.includes(
            ALL_PERMISSIONS.MODAL_HEALTH_SETTINGS_VIEW
          ) ? (
            <Route
              path="/admin/model-health-settings"
              component={ModalHealthSetting}
              exact
            />
          ) : null}
          {FinalPermissions?.includes(ALL_PERMISSIONS.DISCIPLINE_VIEW) ? (
            <Route
              path="/admin/discipline-settings"
              component={DisciplineSettings}
              exact
            />
          ) : null}
          {FinalPermissions?.includes(ALL_PERMISSIONS.REVISION_VIEW) ? (
            <Route
              path="/admin/revision-settings"
              component={RevisionSettings}
              exact
            />
          ) : null}
          {FinalPermissions?.includes(ALL_PERMISSIONS.PRICING_DATABASE_VIEW) ? (
            <Route
              path="/pricing-catalogues"
              component={PricingCatelogs}
              exact
            />
          ) : null}
          {FinalPermissions?.includes(ALL_PERMISSIONS.MODAL_FILE_VIEW) ? (
            <Route path="/modal-file-list" component={ModalFileList} exact />
          ) : null}
          {FinalPermissions?.includes(
            ALL_PERMISSIONS.MODAL_HEALTH_REPORT_VIEW
          ) ? (
            <Route path="/model-health" component={ModalHealth} exact />
          ) : null}
          {FinalPermissions?.includes(
            ALL_PERMISSIONS.MODAL_HEALTH_REPORT_VIEW
          ) ? (
            <Route
              path="/model-health/:reportId"
              exact
              component={({ match }) => {
                const { reportId } = match.params;
                return <Report reportId={reportId} />;
              }}
            />
          ) : null}
          {FinalPermissions?.includes(ALL_PERMISSIONS.PARAMETER_MGMT_VIEW) ? (
            <Route path="/all-parameters" component={Parameters} exact />
          ) : null}

          {FinalPermissions?.includes(
            ALL_PERMISSIONS.PROJECT_EXPORT_SETTINGS_VIEW
          ) ? (
            <Route
              path="/admin/export-settings"
              component={ExportSettings}
              exact
            />
          ) : null}

          {FinalPermissions?.includes(ALL_PERMISSIONS.USER_VIEW) ? (
            <Route
              path="/admin/user-permissions"
              component={UserPermissions}
              exact
            />
          ) : null}

          <Route
            path="/admin/company-settings"
            component={CompanySettings}
            exact
          />

          <Route path="/admin/support-chat" component={SupportChat} exact />

          {FinalPermissions?.includes(ALL_PERMISSIONS.ASSET_MANAGEMENT_VIEW) ? (
            <Route
              path="/assets-management"
              component={AssetManagement}
              exact
            />
          ) : null}

          {FinalPermissions?.includes(ALL_PERMISSIONS.ASSET_MANAGEMENT_VIEW) ? (
            <Route
              path="/assets-management/asset-view/:projectId"
              exact
              component={({ match }) => {
                return <AssetViewer projectId={match.params.projectId} />;
              }}
            />
          ) : null}

          {FinalPermissions?.includes(ALL_PERMISSIONS.FILTER_VIEW) ? (
            <Route path="/filter-view" component={FilterView} exact />
          ) : null}

          {FinalPermissions?.includes(ALL_PERMISSIONS.DOC_CONTROL_VIEW) ? (
            <Route path="/document-control" component={DocControl} exact />
          ) : null}

          {FinalPermissions?.includes(
            ALL_PERMISSIONS.ENGINEERING_TRACKING_VIEW
          ) ? (
            <Route
              path="/resource-tracking"
              component={ResourceTracking}
              exact
            />
          ) : null}

          {FinalPermissions?.includes(ALL_PERMISSIONS.AFFILIATIONS_VIEW) ? (
            <Route path="/earnings" component={Earnings} exact />
          ) : null}

          <Route path="/activity-log" component={ActivityLog} exact />

          <Route
            path="/learning"
            component={() => {
              return <MyApp anonymous={false} userInfoRedux={userInfoRedux} />;
            }}
          />
          <Route path="/permissions" component={Permissions} exact />
          <Route path="/profile" component={Profile} exact />
          <Route path="/request_access" component={RequestAccess} exact />
          <Redirect to="/dashboard" />
        </Switch>
      </div>
    );
  }, [adminFlag, maybeMainAdmin, usersPermissionRedux]);

  if (maybeEmail) {
    return (
      <div>
        {googleAnalytics4(maybeUserID)}
        <>
          {socketviewer ? null : <Header setModal={setModal} />}

          <MDBBox>
            <DownloadSoftwareModal
              isOpen={modal === "DownloadSoftware"}
              toggle={() => setModal("")}
            />
            {pages}
          </MDBBox>
        </>
      </div>
    );
  }

  return (
    <div>
      <DownloadSoftwareModal
        isOpen={modal === "DownloadSoftware"}
        toggle={() => setModal("")}
      />
      {googleAnalytics4(maybeUserID)}
      <Switch>
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/Revit-Estimate-Plugin" exact component={RevitEstimate} />
        <Route path="/" component={Authorization} exact />
        <Route path="/estimation" component={EstimationTemplate} exact />
        <Route
          path="/estimation/:username"
          component={EstimationTemplate}
          exact
        />
        <Route path="/thank-you" component={Thankyou} exact />
        <Route path="/authorization" component={Authorization} exact />
        <Route
          path="/learning"
          component={() => {
            return (
              <>
                <Header setModal={setModal} anonymous={true} />
                <MyApp anonymous={true} />
              </>
            );
          }}
        />

        <Route
          path="/share-project/:shareId"
          exact
          component={() => {
            return <Share anonymous={true} />;
          }}
        />
        <Route
          path="/registration-user-admin/:key"
          exact
          component={({ match }) => {
            return <RegistrationUserAdmin key={match.params.key} />;
          }}
        />
        <Route
          path="/registration-user/:key"
          exact
          component={({ match }) => {
            return <RegistrationUser key={match.params.key} />;
          }}
        />
        <Route
          path="/reset-password/:key/:token"
          exact
          component={({ match }) => {
            return <ResetPassword key={match.params.key} />;
          }}
        />
        <Route
          path="/personal-area/projects/:projectId/timelines/:timelineId/:token"
          exact
          component={({ match }) => {
            return (
              <Viewer
                projectId={match.params.projectId}
                timelineId={match.params.timelineId}
                token={match.params.token}
                personal={true}
              />
            );
          }}
        />
        <Route
          path="/socketviewer/:projectId/:wsSessionId/:token/:companyId"
          exact
          component={({ match }) => {
            return (
              <SocketViewer
                companyId={match.params.companyId}
                projectId={match.params.projectId}
                wsSessionId={match.params.wsSessionId}
                token={match.params.token}
              />
            );
          }}
        />

        <Redirect to="/authorization" />
      </Switch>
    </div>
  );
};

export default App;
