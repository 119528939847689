import {
  SET_USER,
  SET_PROJECTS,
  SET_COMPANY_USERS,
  ADD_NEW_PROJECT,
  UPDATE_IFC_PROJECT,
  REMOVE_PROJECT,
  REMOVE_USER_FROM_PROJECT,
  ADD_USER_TO_PROJECT,
  SET_MILESTONES,
  SET_CATALOGS_TREE,
  SET_CATALOGS,
  UPDATE_MILESTONE,
  SET_PERM,
  FEATURE_REQUEST,
  SET_SIDEBAR,
  SET_COMPANY_PERMISSION,
  SET_ALL_PERMISSION,
  SET_ALL_HEALTH_REPORT,
  SET_ALL_PARAMETERS,
  SET_COMPANY_SETTINGS,
  UPDATE_PROJECT,
  SET_PERSONAL_AREA_ACTIVE_TAB,
  SET_COMPANY_ROLE,
  SET_USER_ANALYTICS,
  REFRESH_PROJECTS,
  PROJECT_FILES_TREE
} from '../constants/action-types/user';

export const setUser = user => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const setProjects = projects => {
  return {
    type: SET_PROJECTS,
    payload: projects,
  };
};
export const setMilestones = milestones => {
  return {
    type: SET_MILESTONES,
    payload: milestones,
  };
};

export const updateMilestone = milestones => {
  return {
    type: UPDATE_MILESTONE,
    payload: milestones,
  };
};

export const addNewProject = project => {
  return {
    type: ADD_NEW_PROJECT,
    payload: project,
  };
};

export const editIfcProject = project => {
  return {
    type: UPDATE_IFC_PROJECT,
    payload: project,
  };
};

export const removeProject = project => {
  return {
    type: REMOVE_PROJECT,
    payload: project,
  };
};

export const setCompanyUsers = users => {
  return {
    type: SET_COMPANY_USERS,
    payload: users,
  };
};

export const removeUserFromProject = (user, project) => {
  return {
    type: REMOVE_USER_FROM_PROJECT,
    payload: { user, project },
  };
};

export const addUserToProject = (user, project) => {
  return {
    type: ADD_USER_TO_PROJECT,
    payload: { user, project },
  };
};

export const addPermissionIsUser = (payload) => {
  return {
    type: SET_PERM,
    payload,
  };
};

export const setCompanyPermission = (payload) => {
  return {
    type: SET_COMPANY_PERMISSION,
    payload,
  };
};

export const setAllPermission = (payload) => {
  return {
    type: SET_ALL_PERMISSION,
    payload,
  };
};

export const setFeatureRequest = (payload) => {
  return {
    type: FEATURE_REQUEST,
    payload,
  };
};

export const setSidebarStatus = (payload) => {
  return {
    type: SET_SIDEBAR,
    payload,
  };
};

export const setPersonalAreaActiveTab = (payload) => {
  return {
    type: SET_PERSONAL_AREA_ACTIVE_TAB,
    payload,
  };
};

export const setAllHealthReports = (payload) => {
  return {
    type: SET_ALL_HEALTH_REPORT,
    payload,
  };
};

export const setAllParameters = (payload) => {
  return {
    type: SET_ALL_PARAMETERS,
    payload,
  }
}

export const setCatalogsTree = (catalogs) => {
  return {
    type: SET_CATALOGS_TREE,
    payload: catalogs,
  };
};

export const setCatalogs = (catalogs) => {
  return {
    type: SET_CATALOGS,
    payload: catalogs,
  };
}

export const setCompanySettings = (payload) => {
  return {
    type: SET_COMPANY_SETTINGS,
    payload,
  };
}

export const updateProjectData = (payload) => {
  return {
    type: UPDATE_PROJECT,
    payload
  }
}

export const setCompanyRole = (payload) => {
  return {
    type: SET_COMPANY_ROLE,
    payload
  }
}

export const setUserAnalytics = (payload) => {
  return {
    type: SET_USER_ANALYTICS,
    payload
  }
}

export const setRefreshProjects = (payload) => {
  return {
    type: REFRESH_PROJECTS,
    payload
  }
}

export const setProjectfilesTree = (payload) => {
  return {
    type: PROJECT_FILES_TREE,
    payload
  }
  
}