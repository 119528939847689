import React from "react";
import QuestionAskView from "../../components/question-ask-view";
import QuestionForm from "../../components/question-ask-view/question-form";
import Seo from "../../components/seo/Seo";

import { eventTrack } from "../../components/utils";
import GA4 from "../../components/GA4";
import Layout from "learning/components/layout";
import { url } from "api";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarStatus } from "actions/user";
import { useHistory } from "react-router";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import Arrow from "assets/images/leftArr.png";
  


const Ask = ({ anonymous}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));


  return (
    <div>
      <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}>
        <Seo
          title={
            "Ask a Question - BidLight BIM Revit Plugin Support & Learning"
          }
          keywords={
            "BidLight Support help Revit BIM Plugin Estimate Cost Proejct Management Tracking"
          }
          siteUrl={`${url}/learning/questions/ask`}
        />
        {eventTrack("Ask", "Ask", "Ask", 4, false)}
        <GA4
          userId={"Ask"}
          category={"Ask"}
          action={"Ask"}
          label={"Ask"}
          value={4}
          nonInteraction={true}
          transport={"xhr"}
        ></GA4>

        <div className="inner-header-position desktop-show">
          <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center">
              <div
                onClick={() => dispatch(setSidebarStatus(true))}
                className="cursor-pointer"
              >
                <img src={Arrow} className="left-arrow-img" />
              </div>
              <p className="my-heading f-14 ml-3 mr-3 cursor-pointer" onClick={()=> history.push('/learning')}>Learning</p>
              <img src={Arrow} className="left-arrow-img" />
              <p className="my-heading f-14 ml-3 mr-3">Ask Question</p>

            </div>
            <div
              className="projActionButtons d-flex flex-wrap"
              style={{ maxWidth: "100vw" }}
            >
              <div className="vr mx-3"></div>
              <div className="btn-tool-group">
                {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                  <>
                    <button
                      onClick={() => {
                        if (
                          FinalPermissions?.includes(
                            ALL_PERMISSIONS.PROJECT_VIEW
                          )
                        ) {
                          history.push(`/admin/projects-settings`);
                        } else if (
                          FinalPermissions?.includes(
                            ALL_PERMISSIONS.MILESTONE_VIEW
                          )
                        ) {
                          history.push(`/admin/milestones`);
                        } else {
                          history.push(`/admin/user-permissions`);
                        }
                      }}
                      className="bg-bid px-3 mr-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="19"
                        viewBox="0 0 14 19"
                        fill="none"
                      >
                        <path
                          d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                          fill="white"
                        />
                      </svg>
                      Admin
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <QuestionAskView>
          <QuestionForm />
        </QuestionAskView>
      </Layout>
    </div>
  );
};

export default Ask;
