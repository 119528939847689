import React from 'react'
import Editor from '../../components/articles/Editor'
import Seo from '../../components/seo/Seo'
import { url } from 'api'
const ArticleEditor = ({ anonymous, userInfoRedux, slug }) => {
  return (
    <>
      <Seo title={'Post Article - BidLight Learning'} siteUrl={`${url}/learning/articles/create/editor`}/>
      <Editor  anonymous={anonymous} userInfoRedux={userInfoRedux} slug={slug}/>
    </>)
}

export default ArticleEditor
