
import React from 'react';
import {
  FacebookShareCount,
  PinterestShareCount,
  // VKShareCount,
  // OKShareCount,
  RedditShareCount,
  // TumblrShareCount,
  // HatenaShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  // VKShareButton,
  // OKShareButton,
  // TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  // TumblrShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // ViberShareButton,
  // WorkplaceShareButton,
  // LineShareButton,
  // WeiboShareButton,
  // PocketShareButton,
  // InstapaperShareButton,
  // HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  // VKIcon,
  // OKIcon,
  // TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  // TumblrIcon,
  // MailruIcon,
  EmailIcon,
  // LivejournalIcon,
  // ViberIcon,
  // WorkplaceIcon,
  // LineIcon,
  // PocketIcon,
  // InstapaperIcon,
  // WeiboIcon,
  // HatenaIcon,
} from 'react-share';


import styles from './share.module.css'
import SiteConfig from '../../site.config'
import { url } from 'api';



const ShareSocial = (shareUrl , title, image, description ) => {
 

    return (
      <div className={styles.Demo__container}> 
        <div className={styles.Demo__some_network}>
          <FacebookShareButton
            url={shareUrl.shareUrl}
            quote={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <div>
            <FacebookShareCount url={shareUrl.shareUrl} className={styles.Demo__some_network__share_count}>
              {count => count}
            </FacebookShareCount>
          </div>
        </div>

        <div className={styles.Demo__some_network}>
          <FacebookMessengerShareButton
            url={shareUrl.shareUrl}
            appId="101390705075858"
            className={styles.Demo__some_network__share_button}
          >
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
        </div>

        <div className={styles.Demo__some_network}>
          <TwitterShareButton
            url={shareUrl.shareUrl}
            title={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <div className={styles.Demo__some_network__share_count}>&nbsp;</div>
        </div>

        {/* <div className={styles.Demo__some_network}>
          <TelegramShareButton
            url={shareUrl.shareUrl} 
            title={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <div className={styles.Demo__some_network__share_count}>&nbsp;</div>
        </div> */}

        <div className={styles.Demo__some_network}>
          <WhatsappShareButton
            url={shareUrl.shareUrl} 
            title={title.title}
            separator=":: "
            className={styles.Demo__some_network__share_button}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <div className={styles.Demo__some_network__share_count}>&nbsp;</div>
        </div>

        <div className={styles.Demo__some_network}>
          <LinkedinShareButton url={shareUrl.shareUrl} className={styles.Demo__some_network__share_button}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>

        <div className={styles.Demo__some_network}>
          <PinterestShareButton
            url={shareUrl.shareUrl} 
            media= {String(image)}
            className={styles.Demo__some_network__share_button}
          >
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <div>
            <PinterestShareCount url={shareUrl.shareUrl} className={styles.Demo__some_network__share_count} />
          </div>
        </div>

        {/* <div className={styles.Demo__some_network}>
          <VKShareButton
            url={shareUrl.shareUrl} 
            image={image}
            className={styles.Demo__some_network__share_button}
          >
            <VKIcon size={32} round />
          </VKShareButton>

          <div>
            <VKShareCount url={shareUrl.shareUrl} className={styles.Demo__some_network__share_count} />
          </div>
        </div>

        <div className={styles.Demo__some_network}>
          <OKShareButton
            url={shareUrl.shareUrl} 
            image={image}
            className={styles.Demo__some_network__share_button}
          >
            <OKIcon size={32} round />
          </OKShareButton>

          <div>
            <OKShareCount url={shareUrl.shareUrl} className={styles.Demo__some_network__share_count} />
          </div>
        </div> */}

        <div className={styles.Demo__some_network}>
          <RedditShareButton
            url={shareUrl.shareUrl} 
            title={title.title}
            windowWidth={660}
            windowHeight={460}
            className={styles.Demo__some_network__share_button}
          >
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div>
            <RedditShareCount url={shareUrl.shareUrl} className={styles.Demo__some_network__share_count} />
          </div>
        </div>

        {/* <div className={styles.Demo__some_network}>
          <TumblrShareButton
            url={shareUrl.shareUrl} 
            title={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <TumblrIcon size={32} round />
          </TumblrShareButton>

          <div>
            <TumblrShareCount url={shareUrl.shareUrl} className={styles.Demo__some_network__share_count} />
          </div>
        </div>

        <div className={styles.Demo__some_network}>
          <LivejournalShareButton
            url={shareUrl.shareUrl} 
            title={title.title}
            description={description}
            className={styles.Demo__some_network__share_button}
          >
            <LivejournalIcon size={32} round />
          </LivejournalShareButton>
        </div>

        <div className={styles.Demo__some_network}>
          <MailruShareButton
            url={shareUrl.shareUrl}
            title={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <MailruIcon size={32} round />
          </MailruShareButton>
        </div> */}

        <div className={styles.Demo__some_network}>
          <EmailShareButton
            url={shareUrl.shareUrl}
            subject={title.title}
            body={description}
            className={styles.Demo__some_network__share_button}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
        {/* <div className={styles.Demo__some_network}>
          <ViberShareButton
            url={shareUrl.shareUrl}
            title={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <ViberIcon size={32} round />
          </ViberShareButton>
        </div>

        <div className={styles.Demo__some_network}>
          <WorkplaceShareButton
            url={shareUrl.shareUrl}
            quote={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>
        </div>

        <div className={styles.Demo__some_network}>
          <LineShareButton
            url={shareUrl.shareUrl}
            title={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <LineIcon size={32} round />
          </LineShareButton>
        </div>

        <div className={styles.Demo__some_network}>
          <WeiboShareButton
            url={shareUrl.shareUrl}
            title={title.title}
            image={image}
            className={styles.Demo__some_network__share_button}
          >
            <WeiboIcon size={32} round />
          </WeiboShareButton>
        </div>

        <div className={styles.Demo__some_network}>
          <PocketShareButton
            url={shareUrl.shareUrl}
            title={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <PocketIcon size={32} round />
          </PocketShareButton>
        </div>

        <div className={styles.Demo__some_network}>
          <InstapaperShareButton
            url={shareUrl.shareUrl}
            title={title.title}
            className={styles.Demo__some_network__share_button}
          >
            <InstapaperIcon size={32} round />
          </InstapaperShareButton>
        </div>

        <div className={styles.Demo__some_network}>
          <HatenaShareButton
            url={shareUrl.shareUrl}
            title={title.title}
            windowWidth={660}
            windowHeight={460}
            className={styles.Demo__some_network__share_button}
          >
            <HatenaIcon size={32} round />
          </HatenaShareButton>

          <div>
            <HatenaShareCount url={shareUrl.shareUrl} className={styles.Demo__some_network__share_count} />
          </div>
        </div> */}
      </div>
    );
  }


export default ShareSocial;

ShareSocial.defaultProps = {
    title: SiteConfig.title,
    shareUrl: SiteConfig.siteUrl,
    image: `'${url}/marketing/branding/hotlink-ok/bidlight-demo.png'`,
    description: SiteConfig.description,
}