import React from 'react'
import SiteConfig from './site.config'
import { HeadProvider} from 'react-head';
import { url } from 'api';



const extractContent = (HTMLPart) => (
    HTMLPart
      .replace(/\n/ig, '')
      .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/ig, '')
      .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/ig, '')
      .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/ig, '')
      .replace(/<\/\s*(?:p|div)>/ig, '\n')
      .replace(/<br[^>]*\/?>/ig, '\n')
      .replace(/<[^>]*>/ig, '')
      .replace('&nbsp;', ' ')
      .replace(/[^\S\r\n][^\S\r\n]+/ig, ' ')
  );

export default function Seo({ title, description, ogImage, siteUrl, keywords, authorUsername }) {
    return (
        <HeadProvider>
           
            <meta content="text/html; charset=UTF-8" httpEquiv="Content-Type"/>
            <meta httpEquiv='x-ua-compatible' content='ie=edge' />
            <title>{title}</title> 
            <meta name="description" content={ extractContent(description)} /> 
            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta property="keywords" content={keywords.toString()} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:image:width" content={526} />
            <meta property="og:image:height" content={275} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={authorUsername} />
            <meta property="og:description" content={extractContent(description)} />
            <meta property="og:site_name" content="BidLight Learning" />
            <meta property='og:url' name='og:url' content={siteUrl} />
            <meta property='twitter:url' name='twitter:url' content={siteUrl} />
            <meta property='url' name='url' content={siteUrl} />
            <link rel='shortcut icon' href='/images/logo.svg' />
            <link rel="canonical" href={siteUrl} />
            <meta property="author" name="author" content={authorUsername} />
            <meta property="image"  name="image" content={ogImage} />
            <meta property="twitter:image"  name="twitter:image" content={ogImage} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

            {/* <meta property="og:description" content={extractContent(`${description.substring(0,220).toString()}...`)} /> */}

        </HeadProvider>
    )
}

Seo.defaultProps = {
    title: SiteConfig.title,
    description: SiteConfig.description,
    ogImage: `'${url}/marketing/branding/hotlink-ok/bidlight-demo.png'`,
    siteUrl: SiteConfig.siteUrl,
    keywords: 'BIM AEC Revit Estimation cost unitcost costdatabase cost Plugin',
    authorUsername: SiteConfig.author.name
}