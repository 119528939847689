import React, { useEffect, useState, useContext } from "react";
import ArticleView from "../../components/articles/ArticleView";
import Seo from "../../components/seo/Seo";
import CONSTANTS from "../../constants";
import { eventTrack } from "../../components/utils";
import GA4 from "../../components/GA4";
import { extractImageFromZip } from "../../utils/imageHandler";
import Layout from "learning/components/layout";
import { url } from "api";
import Loader from "components/Loader";

import { useDispatch, useSelector } from "react-redux";
import { setSidebarStatus } from "actions/user";
import { useHistory } from "react-router";
import { ALL_PERMISSIONS } from "constants/permissionsConstants";
import Arrow from "assets/images/leftArr.png";

const SingleArticle = ({ slug, anonymous }) => {
  const [authorProfilePicture, setAuthorProfilePicture] = useState("");
  const [data, setData] = useState(null);

  const history = useHistory();

  const dispatch = useDispatch();
  const userInfoRedux = useSelector((s) => s.user.userInfo);
  const usersPermissionRedux = useSelector((s) => s.user.userPermission);
  const companyPermissionRedux = useSelector((s) => s.user.companyPermission);
  const allPermissionRedux = useSelector((s) => s.user.allPermission);
  const FinalPermissions = allPermissionRedux
    ?.filter((element) => companyPermissionRedux?.includes(element))
    ?.filter((i) => usersPermissionRedux?.includes(i));

  useEffect(() => {
    const getExtractURL = async () => {
      const extractedProfileImg = await extractImageFromZip(
        data?.aut?.profilePhoto
      );
      setAuthorProfilePicture(extractedProfileImg);
      console.log(extractedProfileImg);
    };
    getExtractURL();
  }, [data?.aut?.profilePhoto]);

  useEffect(() => {
    getServerSideProps(slug);
  }, []);

  const getServerSideProps = async (slug) => {
    const slugg = slug;
    const res = await fetch(
      `${CONSTANTS.SERVER_URL}/api/article/slug/${slugg}`
    );
    const smartNotes = [
      "• a must read",
      "• 1 minute read",
      "• informative read",
      "• easy read",
      "• unexpected read",
    ];

    const singleBlog = await res.json();

    const authorImage = await extractImageFromZip(
      singleBlog.author.profilePhoto
    );
    setAuthorProfilePicture(authorImage);
    singleBlog.author.profilePhoto = authorImage;

    setData({
      desc: singleBlog.description,
      f_img: singleBlog.feature_img,
      aut: singleBlog.author,
      articleSlug: singleBlog.slug,
      text: singleBlog.text,
      title: singleBlog.title,
      claps: singleBlog.claps,
      artcileId: singleBlog._id,
      createdAt: singleBlog.createdAt,
      smartNote: `${smartNotes[Math.floor(Math.random() * smartNotes.length)]}`,
    });
  };


  const replaceHyphensWithSpaces = (text) => {
    return text.replace(/-/g, ' ');
  };

  return (
    <>
      <Seo
        title={`${data?.title} - BidLight Learning`}
        description={data?.desc}
        ogImage={`${CONSTANTS.SERVER_URL}/marketing/branding/hotlink-ok/${data?.f_img}`}
        siteUrl={`${url}/learning/articles/${data?.articleSlug}`}
        keywords={data?.articleSlug}
        authorUsername={data?.aut?.username}
      />

      {eventTrack("Article", data?.articleSlug, data?.articleSlug, 6, false)}
      <GA4
        userId={data?.artcileId}
        category={"Article"}
        action={"Article"}
        label={"Article"}
        value={6}
        nonInteraction={true}
        transport={"xhr"}
      ></GA4>

      <Layout anonymous={anonymous} userInfoRedux={userInfoRedux}>

      <div className="inner-header-position desktop-show">
        <div className="custom-container d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <div
              onClick={() => dispatch(setSidebarStatus(true))}
              className="cursor-pointer"
            >
              <img src={Arrow} className="left-arrow-img" />
            </div>
            <p
              className="my-heading f-14 ml-3 mr-3 cursor-pointer"
              onClick={() => history.push("/learning/articles")}
            >
              Articles
            </p>
            <img src={Arrow} className="left-arrow-img" />
            <p className="my-heading f-14 ml-3 mr-3">{replaceHyphensWithSpaces(slug)}</p>
          </div>
          <div
            className="projActionButtons d-flex flex-wrap"
            style={{ maxWidth: "100vw" }}
          >
            <div className="vr mx-3"></div>
            <div className="btn-tool-group">
              {(userInfoRedux?.admin || userInfoRedux?.companyAdmin) && (
                <>
                  <button
                    onClick={() => {
                      if (
                        FinalPermissions?.includes(ALL_PERMISSIONS.PROJECT_VIEW)
                      ) {
                        history.push(`/admin/projects-settings`);
                      } else if (
                        FinalPermissions?.includes(
                          ALL_PERMISSIONS.MILESTONE_VIEW
                        )
                      ) {
                        history.push(`/admin/milestones`);
                      } else {
                        history.push(`/admin/user-permissions`);
                      }
                    }}
                    className="bg-bid px-3 mr-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                    >
                      <path
                        d="M0.333984 18.3335C0.333984 14.6516 3.31875 11.6668 7.00065 11.6668C10.6826 11.6668 13.6673 14.6516 13.6673 18.3335H0.333984ZM7.00065 10.8335C4.23815 10.8335 2.00065 8.596 2.00065 5.8335C2.00065 3.071 4.23815 0.833496 7.00065 0.833496C9.76315 0.833496 12.0007 3.071 12.0007 5.8335C12.0007 8.596 9.76315 10.8335 7.00065 10.8335Z"
                        fill="white"
                      />
                    </svg>
                    Admin
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

        {!data ? (
          <Loader />
        ) : (
          <div className="learning-bg container">
            {slug && (
              <ArticleView
                author={{ ...data?.aut, profilePhoto: authorProfilePicture }}
                claps={data?.claps}
                description={data?.desc}
                f_img={data?.f_img}
                title={data?.title}
                text={data?.text}
                article_id={data?.artcileId}
                createdAt={data?.createdAt}
                smartNote={data?.smartNote}
                slug={data?.articleSlug}
                // isAuthenticated={isAuthenticated()}
              />
            )}
          </div>
        )}
      </Layout>
    </>
  );
};

export default SingleArticle;
