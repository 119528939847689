import React from "react";
import { Link } from "react-scroll";
import aboutimage from "assets/images/Intersection3.png";
import bidlight from "assets/images/landing/about-us.png";
import bgCircle from "assets/images/landing/Component621.svg";

const AboutUsComponent = () => {
  return (
    <>
      <div className="overflow-hidden bg-custom" id="about">
        <div className="">
          <div className="row">
            <div className="col-lg-6 bg-about-image-new">
              <img src={bidlight} className="about-bg-logo" />
            </div>
            <div className="col-lg-6 ml-auto bg-about-image">
              <img src={bgCircle} className="about-bg-circle" />
              <h2 className="text-white position-relative font-about">
                About Us
              </h2>
              <h2 className="text-white mb-4 h3 font-mission">Our Mission</h2>
              <p className="mb-4 lead-about">
                Our goal at Bidlight is to exceed our customer's expectations.
                We bring together a multitude of skills from construction and
                finance to computer science, in the hopes of delivering on four
                fronts:
              </p>
              <ul className="ul-check mb-5 mt-5 list-unstyled success pb-5">
                <li>
                  Fast ,{" "}
                  <span>
                    {" "}
                    we pride ourselves to keep our estimation process under 10
                    minutes/project{" "}
                  </span>
                </li>
                <li>
                  Accurate ,
                  <span>
                    {" "}
                    we go to great lengths to keep our unit prices accurate and
                    up to date.
                  </span>
                </li>
                <li>
                  Affordable ,
                  <span>
                    {" "}
                    we understand that small companies need our tools more than
                    large organizations and our pricing model reflects that.
                  </span>
                </li>
                <li>
                  Ease of use,
                  <span>
                    {" "}
                    as our CTO stated, "a software has to be useful, before it
                    is reusable".
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsComponent;
