import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHttp } from "hooks/useHttp";
import { getRoomByUserId, getRoomForGuest } from "api/rooms/getRoomDetail";
import {
  getConversationByRoomId,
  getGuestConversationByRoomId,
} from "api/conversation/getConversations";
import addNotification from "helpers/notify";
import { urlWS } from "api";

import SUPPORT_ICON from "../../assets/images/bidAvatar.png";
import USER_ICON from "../../assets/images/user.png";

export const UserConversation = ({ setAllowChat }) => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [roomId, setRoomId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [guestUser, setGuestUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const ws = useRef(null);

  const { loading, request: getRoomDetail } = useHttp({
    requestCallback: getRoomByUserId,
    onLoad: (res) => {
      if (res?.roomId) {
        setRoomId(res?.roomId);
      }
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const { loading: GuestRoomLoading, request: getRoomDetailForGuest } = useHttp(
    {
      requestCallback: getRoomForGuest,
      onLoad: (res) => {
        if (res?.roomId) {
          getGuestConversation(res?.roomId);
          setRoomId(res?.roomId);
          setGuestUser(res?.guest);
        }
      },
      onError: (res) => {
        addNotification(
          res?.err ?? "Something went wrong, please contact support",
          "danger"
        );
      },
    }
  );

  const { loading: conversationLoader, request: getConversation } = useHttp({
    requestCallback: getConversationByRoomId,
    onLoad: (res) => {
      if (res.conversations) {
        setChatMessages(res?.conversations);
      }
    },
    onError: (res) => {
      addNotification("Something went wrong, please contact support", "danger");
    },
  });

  const { loading: guestConversationLoader, request: getGuestConversation } =
    useHttp({
      requestCallback: getGuestConversationByRoomId,
      onLoad: (res) => {
        if (res.conversations) {
          setChatMessages(res?.conversations);
        }
      },
      onError: (res) => {
        addNotification(
          "Something went wrong, please contact support",
          "danger"
        );
      },
    });

  useEffect(() => {
    if (roomId) {
      if (userInfo) {
        getConversation(roomId);
      }
      ws.current = new WebSocket(`${urlWS}/${roomId}`);

      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setChatMessages((prev) => [...prev, data]);
        console.log("WebSocket Messaging", data);
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket Error: ", error);
      };

      ws.current.onclose = () => {
        console.log("WebSocket connection closed");
      };

      return () => {
        ws.current.close();
      };
    }
  }, [roomId]);

  useEffect(() => {
    if (userInfo) {
      getRoomDetail({ participantId: userInfo.id });
    }
  }, [userInfo]);

  const sendMessage = () => {
    if (ws.current && message.trim()) {
      const chatMessage = {
        header: "chat",
        senderId: userInfo ? userInfo?.id : guestUser?._id,
        message: message,
      };
      ws.current.send(JSON.stringify(chatMessage));
      setMessage("");
    }
  };

  const startChatForGuest = (e) => {
    e.preventDefault();
    getRoomDetailForGuest({ firstName, lastName, email, phoneNumber });
  };

  const getFormattedDate = (createdAt) => {
    const date = new Date(createdAt);
    const options = {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  return (
    <div className="">
      <div
        className="d-flex justify-content-center"
        style={{
          marginBottom: "100px",
          width: "400px",
          height: roomId ? "500px" : "415px",
        }}
      >
        <div
          className="card shadow-none"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <div className="card-header d-flex justify-content-between align-items-center p-3 bg-white border-none">
            <p className="chat-head mb-0">
              {" "}
              {roomId ? `Connected` : "Need Help? Chat with Us!"}
            </p>

            <div className="d-flex flex-row align-items-center cursor-pointer">
              <span
                onClick={() => setAllowChat(false)}
                className="cancel-chat-div"
              >
                <i className="fas fa-minus text-muted fa-xs"></i>
              </span>
            </div>
          </div>
          {roomId ? (
            <>
              <div
                className="card-body chat-card-body"
                data-mdb-perfect-scrollbar-init
                style={{
                  position: "relative",
                  overflow: "auto",
                }}
              >
                {chatMessages.map((m) =>
                  m?.senderId?._id === userInfo?.id ||
                  m?.senderId?._id === guestUser?._id ? (
                    <>
                      <div class="d-flex justify-content-end">
                        <p className="small mb-1">{m?.senderId?.name ?? ""}</p>
                      </div>
                      <div class="d-flex flex-row justify-content-end align-items-center pt-1">
                        <div className="d-flex">
                          <div
                            style={{ marginTop: "5px", marginRight: "5px" }}
                            className="d-flex flex-column"
                          >
                            <div>
                              <p
                                style={{
                                  wordBreak: "break-word",
                                }}
                                className="user-msgs"
                              >
                                {m?.message}
                              </p>
                            </div>
                            <div className="d-flex justify-content-end">
                              <p className="text-muted">
                                {m?.createdAt
                                  ? getFormattedDate(m?.createdAt)
                                  : "Jan 1, 12:00 am"}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-column justify-content-end">
                            <img
                              src={m?.senderId?.profilePhotot ?? USER_ICON}
                              alt="avatar 1"
                              style={{
                                width: "45px",
                                minWidth: "45px",
                                borderRadius: "50%",
                                marginBottom: "45px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div class="d-flex justify-content-start">
                        <p className="small mb-1">
                          {/* {m?.senderId?.name} */}
                          Support
                        </p>
                      </div>
                      <div class="d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex">
                          <div className="d-flex flex-column justify-content-end">
                            <img
                              src={SUPPORT_ICON}
                              alt="avatar 1"
                              style={{
                                width: "45px",
                                minWidth: "45px",
                                borderRadius: "50%",
                                marginBottom: "45px",
                              }}
                            />
                          </div>
                          <div
                            style={{ marginTop: "5px", marginLeft: "5px" }}
                            className="d-flex flex-column"
                          >
                            <div>
                              <p
                                style={{
                                  wordBreak: "break-word",
                                  marginTop: "5px",
                                  marginLeft: "5px",
                                }}
                                className="sender-msgs"
                              >
                                {m?.message}
                              </p>
                            </div>
                            <div className="d-flex justify-content-start">
                              <p className="text-muted">
                                {m?.createdAt
                                  ? getFormattedDate(m?.createdAt)
                                  : "Jan 1, 12:00 am"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
              <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3">
                <div className="input-group chat-send-div">
                  <input
                    type="text"
                    className="form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type message"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />
                  <button
                    data-mdb-button-init
                    data-mdb-ripple-init
                    type="button"
                    id="button-addon2"
                    onClick={sendMessage}
                  >
                    <i className="fa fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </>
          ) : loading || conversationLoader || guestConversationLoader ? (
            <p>Setting up things...</p>
          ) : (
            <form onSubmit={startChatForGuest}>
              <div className="px-3 d-flex flex-column gap-3 mt-2">
                <div className="chat-form-div">
                  <i className="fa fa-user"></i>
                  <input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="chat-form-div">
                  <i className="fa fa-user"></i>
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div className="chat-form-div">
                  <i className="fa fa-user"></i>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    required
                  />
                </div>
                <div className="chat-form-div">
                  <i className="fa fa-user"></i>
                  <input
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type="number"
                    className="form-control"
                    placeholder="Enter phone number"
                    required
                  />
                </div>
                <div>
                  <p
                    style={{
                      color: "#8d98a8",
                      fontSize: "13px",
                    }}
                  >
                    If you were already in chat please add your previous email
                    and click "Start Chat"
                  </p>
                  <button
                    type="submit"
                    className="header-btn text-white border-bid bg-bid start-chat-btn poppins mx-auto"
                  >
                    Start Chat
                    {GuestRoomLoading ? (
                      <i className="fa fa-spinner small-spinner" />
                    ) : (
                      <i className="fa fa-arrow-right"></i>
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
